/**
 * Created by Ivan Kubota on 4/12/18.
 * Only the author can use it for commercial purpose right now.
 * You can use email to contact me: zibx@quokka.pub
 */

import Figure from './Figure.js'
import Point from './Primitives/Point.js'


Figure.Key = function(from, to, cfg) {

};

Figure.Key.prototype = {
    _points: ['center'],
    angle: 0,
    draw: function(ctx, time) {
        ctx.rotate(this.angle, ()=> {
            ctx.lineWidth = 1;

            ctx.fillStyle = '#96f1e3';
            ctx.beginPath();


            var np = this.center.addClone(0, -30), vec = new Point(40,0).rotate(Math.PI*2/3+Math.PI);
            ctx.moveTo( np.x, np.y );

            np.add(vec = vec.rotate(Math.PI*2/3))
            ctx.lineTo( np.x, np.y );

            np.add(vec = vec.rotate(Math.PI*2/3))
            ctx.lineTo( np.x, np.y );

            np.add(vec = vec.rotate(Math.PI*2/3))
            ctx.lineTo( np.x, np.y );

            ctx.fill();
            ctx.fillRect(this.center.x - 7, this.center.y, 14, 30)

            ctx.closePath();
        }, this.center)


    },
};


Figure.extend('Key');
