/**
 * Created by Ivan Kubota on 4/12/18.
 * Only the author can use it for commercial purpose right now.
 * You can use email to contact me: zibx@quokka.pub
 */

import Figure from './Figure.js'

var offset = function(offset) {
    for( var i = 0, _i = this._points.length; i < _i; i++ ){
        var pointName = this._points[ i ];
        this[pointName].add(offset);
    }
};


Figure.Basic = function() {};
Figure.Basic.prototype = {
    init: function() {

    },
    offset: offset
};
