<template>
  <div class="start-screen">
<!--    <button style="opacity:0" class="switch-time" type="button" v-on:click="nightOn = !nightOn;this.startTheAnimation()">switch time</button>-->
    <div class="start-screen__loader" :class="[loaderWorking ? 'show' : 'hide']" v-if="!nightOn">
      <div class="loader">
        <div class="loader__icon">
          <svg id="keyhole-icon" width="17" height="27" viewBox="0 0 17 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <linearGradient id="bottom-to-top" x1="0" x2="0" y1="100%" y2="0%">
                <stop offset="0" stop-color="#fff">
                  <animate id="animate0_0" dur="2s" begin="indefinite" attributeName="offset" fill="freeze" from="0" to="0.3" v-on2:endEvent="to30"/>
                  <animate id="animate0_1" dur="2s" begin="indefinite" attributeName="offset" fill="freeze" from="0.3" to="0.6"  v-on2:endEvent="to60"/>
                  <animate id="animate0_2" dur="2s" begin="indefinite" attributeName="offset" fill="freeze" from="0.6" to="1" v-on2:endEvent="to100"/>
                </stop>
                <stop offset="0" stop-color="#000">
                  <animate id="animate1_0" dur="2s" begin="indefinite" attributeName="offset" fill="freeze" from="0" to="0.3"/>
                  <animate id="animate1_1" dur="2s" begin="indefinite" attributeName="offset" fill="freeze" from="0.3" to="0.6"/>
                  <animate id="animate1_2" dur="2s" begin="indefinite" attributeName="offset" fill="freeze" from="0.6" to="1"/>
                </stop>
              </linearGradient>
            </defs>
            <path id="keyhole" d="M12.1253 13.8146C11.8825 13.9226 11.7397 14.1775 11.7744 14.4409L13.3183 26.1931H3.82561L5.15023 14.4299C5.1795 14.1699 5.03699 13.921 4.79801 13.8146C3.58966 13.2767 2.78562 12.5718 2.27462 11.6946C1.75921 10.8099 1.51069 9.69623 1.51069 8.29137C1.51069 4.60519 4.61848 1.57949 8.5 1.57949C12.3815 1.57949 15.4893 4.60519 15.4893 8.29137C15.4893 9.68957 15.2243 10.8018 14.6915 11.6874C14.1619 12.5678 13.337 13.2753 12.1253 13.8146Z" stroke="currentColor" stroke-width="1.2" stroke-miterlimit="10" stroke-linejoin="round" stroke-dashoffset="10" stroke-dasharray="200%, 100%" fill="url(#bottom-to-top)">
              <animate attributeName="stroke-dashoffset" dur="10s" begin="0s" repeatCount="indefinite" values="250;0" />
            </path>
          </svg>

        </div>
        <p class="loader__legend">WIRD GELADEN</p>
      </div>
    </div>
    <div class="start-screen__late" v-if="nightOn">
      <div class="enter-message">
        <div class="enter-message__icon">
          <MoonIcon />
        </div>
        <div class="enter-message__warning">
          <h3>WIR HABEN LEIDER GESCHLOSSEN!</h3>
          <p>Wiederkommen lohnt sich. Mit etwas Glück kannst du Geschenkkarten im Wert von bis zu 1000€&nbsp;gewinnen.<br>
            Wir freuen uns auf dich innerhalb unserer Öffnungszeiten:
          </p>

          <p>Montag - Samstag: 10:00 - 20:00 Uhr</p>
          <Button to="/about" primary value="Erfahre mehr" />
        </div>
      </div>
    </div>
    <div class="start-screen__8thwall" v-if="!nightOn" :class="[!loaderWorking ? 'hide' : 'show']">
<!--      <img src="https://hm-home-store-opening.com/images/8thwall.png" width="85" alt="">-->
    </div>
    <div class="start-screen__enter"  v-if="!nightOn" :class="[loaderWorking ? 'hide' : 'show']">
      <Enter />
    </div>
    <a href="https://www2.hm.com/fr_fr/maison.html" class="start-screen__logo" target="_blank" aria-label="Go to HM Home site">
      <LogoIcon />
    </a>
  </div>
</template>

<script>
import LogoIcon from '@/assets/images/logo.svg?inline'
import Enter from '@/components/Enter.vue'
import MoonIcon from '@/assets/images/moon.svg?inline'
import Button from '@/components/Button.vue'

export default {
  name: 'StartScreen',
  components: {
    LogoIcon,
    Enter,
    MoonIcon,
    Button
  },
  data () {
    return {
      loaderWorking: true,
      nightOn: false
    }
  },
  directives: {
    on2: {
      mounted (el, binding) {
        el.addEventListener(binding.arg, binding.value)
      }
    }
  },
  async mounted(a,b,c){

    var updateNight = async () => {
      var isNight = await Ajax.async.get('/api/isNight');
      if(!isNight.wait || isNight.wait<0){
        isNight.wait = 60 * 1000 * 30;
        isNight.is = true;
      }
      this.nightOn = isNight.is;

      setTimeout(updateNight, isNight.wait);
    }
    await updateNight();
    this.startTheAnimation();


  },
  unmounted(){
    this.loadingsSub && this.loadingsSub();
  },
  methods: {
    startTheAnimation(){
      setTimeout(()=>{
      this.loadingsSub && this.loadingsSub();
      var animates = [].slice.call(this.$el.querySelectorAll('animate')).reduce(function(store, el) {
        var tokens = el.id.replace('animate', '').split('_');
        (store[tokens[0]] || (store[tokens[0]] = []))[tokens[1]-0] = el;
        return store;
      }, []);

      this.loadingsSub = store.sub(['sceneKeyLoaded', 'sceneDoorLoaded'], function(a,b) {
        animates.forEach(function(arr) {
          arr[a+b].beginElement();
        })
      });
      }, 30);
    },
    to30 (e) {
      //this.loaderWorking = false
    },
    to60 (e) {
      //this.loaderWorking = false
    },
    to100 (e) {
      this.loaderWorking = false
    }
  }
}
</script>

<style lang="scss">
@import "./../assets/styles/variables.scss";
.start-screen {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 30px 30px 40px;
  max-height: 100%;
  background-color: $background-color;
  transition: all 0.3s ease;

  &::before {
    content: '';
    position: absolute;
    top: 30px;
    left: 30px;
    bottom: 40px;
    right: 30px;
    border: 1px solid $foreground-color;
  }
}

.start-screen__logo {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -135%);
  display: inline-block;
  vertical-align: top;
  box-sizing: content-box;
  padding: 0 30px;
  width: 121px;
  background-color: $background-color;

  svg {
    display: block;
    width: 100%;
  }
}

.start-screen__loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
  &.hide {
    opacity: 0;
    pointer-events: none;
  }
}

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader__icon {
  width: 17px;
  height: 27px;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.loader__legend {
  margin: 10px 0;
  text-transform: uppercase;
  font-family: $accent-font;
  font-size: 14px;
  line-height: 16.8px;
  letter-spacing: 1.2px;
  animation-name: blink;
  animation-duration: 3.5s;
  animation-iteration-count: infinite;
  animation-delay: 0.01s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
}

.start-screen__enter {
  position: relative;
  transition: opacity 0.3s ease;
  z-index: 1;
  &.hide {
    opacity: 0;
    pointer-events: none;
  }
}

.start-screen__late {
  position: relative;
  z-index: 1;
}

.switch-time {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
}

.enter-message__warning {
  margin: 0 auto;
  padding: 0;
  max-width: 280px;
  text-align: center;
  font-family: $font;
  font-size: 16px;
  line-height: 19.09px;
  letter-spacing: 0.2px;
  h3 {
    margin-bottom: 25px;
    font-family: $accent-font;
    text-transform: uppercase;
    font-size: 11px;
    line-height: 13.2px;
    letter-spacing: 1.2px;
  }
  p {
    margin-bottom: 1.4em;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .button {
    margin-top: 10px;
  }
}

.start-screen__8thwall {
  position: absolute;
  bottom: 15%;
  left: 50%;
  transform: translate(-50%, 0);

  &.hide {
    opacity: 0;
    pointer-events: none;
  }

  img {
    display: block;
    margin: 0 auto;
    width: 50px;
    height: auto;
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  70% {
    opacity: 0;
  }
  85% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
</style>
