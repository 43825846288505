var  Lerp = function(a, b, t){
  return a + (b - a) * t
};
var linear = function(from, to) {
  return function(n) {
    return n < from ? 0 : n > to ? 1 : (n-from)/(to-from);
  }
}
linear.fn = function(from, to, n) {
    return n < from ? 0 : n > to ? 1 : (n-from)/(to-from);
}
var revealOpacity = function(f) {
  return function(time) {
    var val = this.obj.material.opacity = f.call(this,time);
    this.obj.visible = val > 0;
    return val;
  }
};
var fadeOpacity = function(f) {
  return function(time) {
    var val = 1-f.call(this, time);
    this.obj.visible = val > 0;
    return this.obj.material.opacity = val;
  }
};
var Init = function(f) {
  this.fns = [].slice.call(arguments);
};
var setSide = function(side) {
  return function(child) {
    child.material.side = side;
  }
};
var move = function(v) {
  return function(child) {
    for(var i in v)
      child.position[i]+=v[i];
  }
};
Init.prototype = {
  init: function(child) {
    for( var i = 0, _i = this.fns.length; i < _i; i++ ){
      var fn = this.fns[ i ];
      fn(child);
    }
  }
};
var Pipeline = function(a, fn) {
  if(typeof a === 'number'){
    this.renderOrder = a;
    var args = [].slice.call( arguments, 1 );
  }else{
    args = [].slice.call( arguments );
  }
  var inits = this.inits = [];
  args = args.filter(function(a) {
    if(a instanceof Init){
      inits.push(a);
    }
    return typeof a === 'function';
  });
  this.step = function(time) {
    for( var i = 0, _i = args.length; i < _i; i++ ){
      var arg = args[ i ],
        val = arg.call(this, time);
      if(!val)
        break;
    }

  };
};
Pipeline.prototype = {
  fn: function(child) {
    this.renderOrder && (child.renderOrder = this.renderOrder);
    this.inits.forEach(i=>i.init(child));
    child.material.transparent = true
    this.step(0);
  }
};
/*BlackRectangleGrow - reveal opacity - 25-50 Fade 100-101
Btn_Inside - reveal opacity - 410-420
Btn_circle - reveal opacity - 410-420 Animation loop 0-200
DoorBackground - reveal opacity - 250-270
DoorLP003 - fill opacity 150-225 (наличник)
DoorOpen001 - fill opacity 150-225 Open 385-435
KeyLP002 - animation 100-380
LabelToGrow reveal opacity 0-20  Fade 80-100
SphereAll002 - Fade 470-500
Video - start 100 end 269 -Texture file DoorHR.mp4
reveal opacity  100-101 Fade 269-270
Door Lines
DBirdoak1_00 - 6 - Start at 420 Loop 400
DBirdoak2_00 - 6 - Start at 420 Loop 330
DBirdoak3_00 - 6 - Start at 380 Loop 370
DBirdoak4_00 - 6 - Start at 420 Loop 330*/

var TheDoor = function() {
  return {
    step: function(time) {
      this.newMaterial.opacity = time;
      this.obj.material.opacity = time;
      //this.obj.children[0].material.opacity = time;
    },
    fn: function( child ){
      var newMaterial = child.material.clone();
      newMaterial.transparent = true;
      var from = 20, to = 200;
      newMaterial.onBeforeCompile = function( shader ){
        shader.transparent = true;
        shader.vertexShader = shader.vertexShader
          .replace( `void main() {`, `varying vec4 vPosition;
void main() {
vPosition = modelMatrix * vec4(position, 1.0);` )
        ;
        shader.fragmentShader = shader.fragmentShader.replace(
          `uniform float`,
          `
varying vec4 vPosition;
varying float noise;

uniform float`
        ).replace(
          '#include <dithering_fragment>',
          `#include <dithering_fragment>
float time = opacity;
vec4 pos = vPosition;

if(time<${from.toFixed(1)}){
    gl_FragColor.a = 0.0;// *= sin(vUv.y*10.0+uTime)>0.5?1.0:0.0;
}else if(time >${to.toFixed(1)}){
    gl_FragColor.a = 1.0;
}else{
    vec3 color = mix(vec3(gl_FragColor), vec3(1.0,1.0,1.0), 1.0+(time-${from.toFixed(1)})/${(from-to).toFixed(1)});
    float alpha0 = (1.0-(vUv.y+(time-${from.toFixed(1)})/${(from-to).toFixed(1)})*1.0);
    gl_FragColor = vec4( color.rgb, alpha0);
    gl_FragColor.a = alpha0;

}
    //gl_FragColor.r = abs((vUv.y*(time*${(from-to).toFixed(1)}/${to.toFixed(1)})));//1.0-pos.z/(time-250.0)*1.0;
` );
      }
      child.material = newMaterial;

      //child.children[0].material = newMaterial
      this.newMaterial = newMaterial;
    }
  }
};
window.clc = function(time,dt) {
  this.obj.rotateY(dt/160*6.28)
};
window.k = function(plane1, plane2, sceneControl) {
  Object.assign(sceneControl.group.quaternion, plane1.quaternion)
  sceneControl.group.scale.x = sceneControl.group.scale.y = sceneControl.group.scale.z =  plane1.scale.x/104*120;
  var p = plane1.position.clone();
  p.add(
    new THREE.Vector3(-plane1.scale.y*0.94, -plane1.scale.x*1.46,-plane1.scale.y*0.15)
      .applyEuler(plane1.rotation)
  )

  var T = sceneControl.getTime();
  var anim = Math.min(T/3,1);

  p.add(new THREE.Vector3(0, -plane1.scale.x*0.31*anim,0))

  Object.assign(sceneControl.group.position, p);
}
var doorLoader = {
  position: function(plane1, plane2, sceneControl) {
    window.k.apply(this, arguments);
  },
  load: function(){
    this.doorScene = new THREE.Scene();
    var standAloneKey = this.standaloneKey = new THREE.Scene();

    var modelFileName = 'Door_F_2.glb',

      lines = window.doorLines = {
        //'Point_Door':    { from: 0, to: 200, start: 0, loop: false, opacity: 30},
        'DBirdoak1': { from: 0, to: 400, start: 360, opacity: 30 },
        'DBirdoak2': { from: 0, to: 330, start: 300, opacity: 100 },
        'DBirdoak3': { from: 0, to: 380, start: 470, opacity: 50 },
        'DBirdoak4': { from: 0, to: 330, start: 500, opacity: 30 },

      },
      objects = this.objects = {
        'Video': {
          fn: function(child) {
            var v = this.video = D.h('video', {
renderTo:document.body,
                playsInline: true,
                'webkit-playsinline': true,
                muted: true,
                /*loop: true,*/
                autoPlay: true,
                width: 665,
                height: 1200,
                src: 'DoorHR.mp4',

              style: { display: 'none' }
            });
            v.src = 'DoorHR.mp4';
            v.load();

            var videoImage = document.createElement( 'canvas' );
            videoImage.width = 665;
            videoImage.height = 1200;

            var videoImageContext = this.videoImageContext = videoImage.getContext( '2d' );
            // background color if no video present
            videoImageContext.fillStyle = '#000000';
            videoImageContext.fillRect( 0, 0, videoImage.width, videoImage.height );

            var videoTexture = this.videoTexture = new THREE.Texture( videoImage );
            videoTexture.minFilter = THREE.LinearFilter;
            videoTexture.magFilter = THREE.LinearFilter;

            var movieMaterial = new THREE.MeshBasicMaterial( { map: videoTexture, overdraw: true, side:THREE.DoubleSide } );

            child.rotateX(Math.PI)

            child.material = movieMaterial;
/*//Create screen
            const screen = new THREE.PlaneGeometry(1, 1, 0);
            const videoScreen = new THREE.Mesh(screen, videoMaterial);
            scene.add(videoScreen);*/

            child.renderOrder = 2;
          },
          step: function(time) {
            var isVisible = time>=100 && time<270;

            this.obj.visible = isVisible;
            if(isVisible !== this.isVisible){
              if(isVisible === true){
                this.playing = true;
                ARController.objects.Video.video.currentTime = 0;
                this.video.play();
              }else{
                this.playing = true;
                ARController.objects.Video.video.currentTime = 0;
                this.video.pause();
              }
            }
            this.isVisible = isVisible;
            if(!this.obj.visible)
              return;


            var video = this.video,
              videoImageContext = this.videoImageContext,
              videoTexture = this.videoTexture;

            if ( video.readyState === video.HAVE_ENOUGH_DATA )
            {
              videoImageContext.drawImage( video, 0, 0 );
              if ( videoTexture )
                videoTexture.needsUpdate = true;
            }
          }
        },
        DoorBackground: new Pipeline( 1, revealOpacity(linear(250, 270)) ),
        Btn_Inside: new Pipeline( 5,revealOpacity(linear(410, 420)) ),
        Btn_circle: {
          fn: function(child, subScene, animations, clip) {
            child.renderOrder = 5
            child.material.opacity = 0;
            delete animations[child.name];
          },
          step: function(time, dt) {
            this.obj.material.opacity = linear.fn(410,420,time);
            window.clc.call(this, time, dt);

          },
          selfAnimate: true
        },//new Pipeline( 5, cycleAnimation(0, 200), revealOpacity(linear(410, 420)) ),
        DoorLP003: new Pipeline( 1,revealOpacity(linear(150, 225)) ),
        DoorOpen001: new Pipeline( 1,revealOpacity(linear(150, 225)) ),
        LabelToGrow: new Pipeline(3,
          revealOpacity(linear(0, 20)),
          fadeOpacity(linear(80, 100))
        ),
        //move({z: 0.1})
        SphereAll002: new Pipeline(new Init(setSide(3), move({z: 0.03})), revealOpacity(linear(270, 290)), fadeOpacity(linear(470, 500))),
        SphereAll001: new Pipeline(new Init(setSide(3)), revealOpacity(linear(450, 470))),
        /*KeyLP002: {
          fn: function(child) {

          }
        },*/
        BlackRectangleGrow: {
          fn: function(child) {
            child.material.transparent = true
          },
          step: function(time) {
            /*this.obj.material.opacity = time < 25 ? 0 : time >50 ? 1 :(time-25)/25;
            this.obj.visible = time<80/24*30;*/
            this.obj.material.opacity = time < 25 ? 0 : time >50 ? 1 :(time-25)/25;
            if(time<80/24*30){

            }else{

              this.obj.material.opacity = 1;
              var anim = Math.min((time-80/24*30)/ 15,1)
              Object.assign(this.obj.position, new THREE.Vector3(1.1581406593322754, 2.2202634811401367, 0.059456802904605865).lerp(new THREE.Vector3(1.1581406593322754, 4.5202634811401367, 0.05996802904605865), anim))
              this.obj.scale.z = Lerp(1.07, 0.27,anim)
            }

          }
        },
        'Oclusion': {
          fn: function( child ){
            child.material.colorWrite = false
            child.renderOrder = 0
          }
        },
/*        'SphereAll001': {
          fn: function( child ){
            child.material.transparent = true
            //scene.children[174].children[4].material.opacity = 0.5
          },
          step: function(time) {
            this.obj.material.opacity = Math.min((time-50)/200,1);
          }
        },*/
        /*Box001: {
          fn: function(child) {
            child.renderOrder = 1
            child.material.transparent = true;

          }/!*,
                        step: function(time) {
                            this.obj.material.opacity = Math.min((time-50)/200,1);
                        }*!/
        },
        Vase2_: new ObjInRoom('Vase2_'),
        Carpet: new ObjInRoom('Carpet'),
        Group7222: new ObjInRoom('Group7222'),
*/
        //'DoorLP003': new TheDoor(),
        //'DoorOpen001': new TheDoor(),
      },
      sceneAnimationLength = 500;

    this.door = initScene({
      scene: this.doorScene,
      camera: this.ctx.camera,
      renderer: this.ctx.renderer,
      modelFileName,
      objects,
      lines,
      sceneAnimationLength,
      loadCb: function() {
        var key = ARController.door.scene.children[1]
          .children
          .filter(a=>a instanceof THREE.Group)[0]
          .children
          .filter(a=>a.name.indexOf('KeyLP002')>-1)[0];

        ( new THREE.TextureLoader() ).load(
          'images/bc.jpg',
          (texture) => {
            texture.flipY = false;
            key.material.map = texture;

            ( new THREE.TextureLoader() ).load(
              'images/norm.jpg',
              (texture) => {
                texture.flipY = false
                key.material.normalMap = texture;
                key.material.normalScale.x = key.material.normalScale.y = 2.5


                key.material.metalness = 0.85;
                Object.assign(key.material.emissive,{r: 0.1, g: 0.1, b: 0});

                var keyMaterial = key.material.clone();
                keyMaterial.transparent = true;
                keyMaterial.onBeforeCompile = function(shader){
                  shader.transparent = true;
                  shader.fragmentShader = shader.fragmentShader.replace( `uniform float`, `
      varying vec4 worldCoord;
      uniform float` ).replace( `#include <dithering_fragment>`,

                    `#include <dithering_fragment>

        float time = opacity;

        float from = -2.4;
        float to = 0.3;
        float d = to-from;

        float cycle = 4000.0;
        float repeat = mod(time, cycle)/cycle*2.0;
        float opacityRepeat = repeat < 1.0 ? 1.0 - repeat: 1.0;
        float colorRepeat = repeat > 1.0 ? 1.0 - (repeat-1.0): 0.0;

        float scaledOpacity = (worldCoord.y-from)/d+(worldCoord.x*sin((opacityRepeat*1.1)*10.0+worldCoord.z*4.0+worldCoord.y*5.0));
        float scaledColor = (worldCoord.y-from)/d+(worldCoord.x*sin(colorRepeat*10.0+worldCoord.z*7.0+worldCoord.y*2.0));
        float a = 0.0;

        vec3 color = vec3(1.0,1.0,1.0);

        if(opacityRepeat == 1.0 || scaledOpacity>=opacityRepeat-0.01){
            a = 0.7;
            if(colorRepeat>0.0 && scaledColor>=colorRepeat){
                a = 1.0;
                color = mix(color, vec3(gl_FragColor), 1.0 - colorRepeat);
            }
        }


        gl_FragColor = vec4(color, a);

      ` );
                  shader.vertexShader = shader.vertexShader
                    .replace( `void main() {`, `
      varying vec4 worldCoord;

      void main() {
        worldCoord = vec4( position, 1.0 );


            ` );


                }
                key.material = keyMaterial;
                key.material.opacity = 3999;
                var clonedKey = window.clonedKey = key.clone();
                ARController.standaloneKey.add(clonedKey);
                var mainLight = new THREE.PointLight(0xffffff,0.8);
                mainLight.position.set(3, 10, 10);
                ARController.standaloneKey.add( mainLight );
                ARController.door.scene.children[1].children.filter(a=>a.type !== 'Group').forEach(a=>a.renderOrder = 2)

                store.set('sceneDoorLoaded', true);

              } );

          } );
      }
    });

    ARController.standaloneKey.add(new THREE.AmbientLight(0x404040, 5));
    /**/

  }
}

export default doorLoader;
