<template>
  <component :class="[pattern ? ['button', pattern] : 'button primary']" :is="type" :to="to">
    {{ value }}
  </component>
</template>

<script>
export default {
  computed: {
    type () {
      if (this.to) {
        return 'router-link'
      }
      return 'button'
    }
  },
  props: {
    to: {
      required: false
    },
    value: {
      type: String
    },
    pattern: {
      required: false,
      type: String
    }
  }
}
</script>

<style lang="scss">
@import "./../assets/styles/variables.scss";
.button {
  display: inline-block;
  vertical-align: top;
  box-sizing: border-box;
  padding: 15px;
  min-width: 130px;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: $accent-font;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 2.5px;
  color: $foreground-color;
  background-color: transparent;
  border: 0;
  border-radius: 50px;
  cursor: default;

  &.primary {
    color: $black;
    background-color: $white;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
}
</style>
