/**
 * Created by Ivan Kubota on 4/12/18.
 * Only the author can use it for commercial purpose right now.
 * You can use email to contact me: zibx@quokka.pub
 */

import Point from './Objects/Primitives/Point.js';

var CameraCTX = function (ctx) {
    this.ctx = ctx;
    this.fillStyle = '#000';
    this.strokeStyle = '#000';
    this.zoom = 1;
    this.pos = new Point(0,0);
    this.rotation = 0;
    this.debugs = [];
};
//el.getContext('2d').arc(x, y, radius, startAngle, endAngle, anticlockwise)
CameraCTX.sharp = {
    morphX: function(x){
        return (((x-this.pos.x)*this.zoom)|0)+0.5;
    },
    morphY: function(y){
        return (((y-this.pos.y)*this.zoom)|0)+0.5;
    }
};
CameraCTX.prototype = {
    _frameEnded: false,
    _frameStarted: false,
    cleanFrameEnd: function() {

    },
    cleanFrameStart: function() {
        if(this._frameStarted && !this._frameEnded){
            this.frameEnd();
        }
        this.clean();
        this.frameStart();
    },
    clean: function() {
        this.clear(0,0,this.width,this.height);

    },
    frameStart: function() {
        this._frameStarted = true;
        this._frameEnded = false;
        /*var dx = this.pos.x*this.zoom,
            dy = this.pos.y*this.zoom;
        this.ctx.translate(-dx,-dy);
        this.ctx.rotate(this.rotation);
        this.ctx.translate(dx,dy);*/
    },
    frameEnd: function() {

        this._frameEnded = true;
        this.ctx.resetTransform();
        /*
        this.ctx.translate(this.pos.x*this.zoom,this.pos.y*this.zoom);
        this.ctx.rotate(-this.rotation);
        this.ctx.translate(-this.pos.x*this.zoom,-this.pos.y*this.zoom);*/
        //this.ctx.setTransform(1, 0, 0, 1, 0, 0);
    },

    morphX: function(x, y){
        return (x-this.pos.x)*this.zoom;
    },
    morphY: function(y){
        return (y-this.pos.y)*this.zoom;
    },
    morphZoom: function(val){
        return val*this.zoom;
    },
    morphPoint: function(p){
        return new Point(this.morphX(p.x,p.y),this.morphY(p.y))
    },
    pointFromGlobal: function(p){
        return new Point(p.x/this.zoom+this.pos.x,p.y/this.zoom+this.pos.y)
    },
    globalFromPoint: function(p){
        return new Point((p.x-this.pos.x)*this.zoom,(p.y-this.pos.y)*this.zoom)
    },
    clearRect: function (x,y,width, height) {
        this.ctx.clearRect(this.morphX(x,y),this.morphY(y),this.morphZoom(width),this.morphZoom(height));
    },
    fillRect: function (x,y,width, height) {
        this.ctx.fillRect(this.morphX(x,y),this.morphY(y),this.morphZoom(width),this.morphZoom(height));
    },
    strokeRect: function (x,y,width, height) {
        this.ctx.strokeRect(this.morphX(x,y),this.morphY(y),this.morphZoom(width),this.morphZoom(height));
    },
    clear: function(x,y,width, height) {
        this.ctx.clearRect(x,y,width, height);
    },

    center: function(x, y, fn) {
        x = this.morphZoom(x);
        y = this.morphZoom(y);
        this.ctx.translate( x, y );
        fn.call(this, this);
        this.ctx.translate( -x, -y );
    },
    rotate: function(angle, fn, point) {
        var mx = this.morphX(point?point.x:0, point?point.y:0),
            my = this.morphY(point?point.y:0);

        this.ctx.translate( mx, my );
        this.ctx.rotate( angle );
        this.ctx.translate( -mx, -my );
        fn.call(this, this);
        this.ctx.translate( mx, my );

        this.ctx.rotate( -angle );
        this.ctx.translate( -mx, -my );

    },

    beginPath: function () {
        this.ctx.beginPath();
    },
    closePath: function () {
        this.ctx.closePath();
    },
    moveTo: function (x, y) {
        this.ctx.moveTo(this.morphX(x, y), this.morphY(y));
        if(!this.suspend){
            this.lastPos = new Point( x, y );
        }
    },
    lineTo: function (x, y) {
        this.ctx.lineTo(this.morphX(x, y), this.morphY(y));

        var currentPos = new Point(x,y);
        if(cfg.get('DEBUG_PATH')){
            this.debugs.push({from: this.lastPos, to: currentPos});
        }
        if(!this.suspend){
            this.lastPos = new Point( x, y );
        }
    },
    stroke: function () {
        this.ctx.stroke();
    },
    fill: function () {
        this.ctx.fill();
    },
    arc: function (x, y, radius, startAngle, endAngle, anticlockwise) {
        this.ctx.arc(this.morphX(x, y), this.morphY(y), this.morphZoom(radius), startAngle, endAngle, anticlockwise);

        if(cfg.get('DEBUG_PATH') && radius>4){
            var from = new Point(radius,0).rotate(startAngle).add(x,y),
                to = new Point(radius,0).rotate(endAngle).add(x,y);

            this.debugs.push({
                from: from,
                to:  to
            });
            if(!this.suspend){
                this.lastPos = to;
            }
        }

    },
    /*drawImage: function(image, x, y, dw, dh) {
        this.ctx.drawImage(image, this.morphX(x), this.morphY(y), this.morphZoom(dw), this.morphZoom(dh))
    },*/
    drawImage: function (img, x,y , w,h, r,dx,dy) {
        dx = dx || 0;
        dy = dy || 0;
        var coord = this.morphPoint(new Point(x,y));

        var wPx = this.morphZoom(w);
        var hPx = this.morphZoom(h);
        this.ctx.translate(coord.x, coord.y);
        if(r){
            this.ctx.rotate( r );
        }
        this.ctx.drawImage(img, -wPx/2+dx*wPx, -hPx/2+dy*hPx, wPx,hPx);
        if(r){
            this.ctx.rotate( -r );
        }
        this.ctx.translate(-coord.x, -coord.y);
    },
    fillText: function (text, x, y, maxWidth) {
        this.ctx.font = ((10*this.zoom)|0)+'px Arial';
        this.ctx.fillText(text, this.morphX(x, y), this.morphY(y), maxWidth)
    },
    setLineDash: function (arr) {
        var zoom = this.zoom;
        this.ctx.setLineDash(Array.isArray(arr)?arr.map(function(el) {
            return el*zoom;
        }):arr);
    },
    drawDebug: function() {
        this.suspend = true;
        for( let i = 0, _i = this.debugs.length; i < _i; i++ ){
            const debug = this.debugs[ i ];
            R.vector(debug, '#f04', 0.5)
        }
        this.suspend = false;
        this.debugs = [];

    },

    try: function(cfg){
        console.log({start:  MODE_DATA.start+'', from:  MODE_DATA.from+'', to:  cfg.to+''})
        this.pos = MODE_DATA.start.addClone(MODE_DATA.from.subClone(this.globalFromPoint(cfg.to)).div(this.zoom));
    },
    doZoom: function(direction, point){
        var local = this.pointFromGlobal(point);
        var prevZoom = this.zoom;

        this.zoom = direction?this.zoom*1.1:this.zoom/1.1;
        var newLocal = this.globalFromPoint(local)
        this.pos.add(newLocal.sub(point).div(this.zoom));
    },
    _sharp: false,
    _lineWidth: 1,
    _rawLineWidth: 1
};

Object.defineProperty(CameraCTX.prototype, 'lineWidth', {
    set: function (w) {
        this._lineWidth = w;
        this._rawLineWidth = this.ctx.lineWidth = this.morphZoom(w);
    },
    get: function() {
        return this._lineWidth;
    }
});
Object.defineProperty(CameraCTX.prototype, 'fillStyle', {
    set: function (style) {
        if(this._fillStyle !== style){
            this._fillStyle = style;
            this.ctx.fillStyle = style;
        }
    },
    get: function() {
        return this._fillStyle;
    }
});
Object.defineProperty(CameraCTX.prototype, 'strokeStyle', {
    set: function (style) {
        if(this._strokeStyle !== style){
            this._strokeStyle = style;
            this.ctx.strokeStyle = style;
        }
    },
    get: function() {
        return this._strokeStyle;
    }
});
Object.defineProperty(CameraCTX.prototype, 'sharp', {
    set: function (val) {
        this._sharp = val;
        if(val){
            this.morphX = CameraCTX.sharp.morphX;
            this.morphY = CameraCTX.sharp.morphY;
        }else{
            delete this.morphX;
            delete this.morphY;
        }
    },
    get: function() {
        return this._sharp;
    }
});

export default CameraCTX;
