<template>
  <div class="enter-message">
    <div class="enter-message__text">
      <p>QR Code scannen, um Tür zu öffnen. Gewinnchance auf Geschenkkarten im Wert von bis zu 1.000€!</p>
      <Button :onclick="next" primary value="START" />
    </div>
  </div>
  <CookiesWarning />
</template>

<script>
import CookiesWarning from '@/components/CookiesWarning.vue'
import Button from '@/components/Button.vue'

export default {
  name: 'Enter',
  components: {
    CookiesWarning,
    Button
  },
  methods: {
    next: function() {
      router.push({path: 'contextual-help2'});
      //router.push({path: store.get('enterPoint') === 'door' ? 'contextual-help2'  : 'contextual-help1'});
    }
  }
}
</script>

<style lang="scss">
@import "./../assets/styles/variables.scss";
.enter-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-family: $accent-font;

  a {
    min-width: 240px;
  }

  .button {
    width: 100%;
    max-width: 240px;
  }
}

.enter-message__text {
  margin: 24px auto 0;
  max-width: 265px;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 1.2;
  p {
    margin: 0 0 1em;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.enter-message__icon {
  margin-bottom: 6px;
  width: 139px;
  height: 122px;

  @media (max-height: 636px) {
    margin-top: -20px;
    height: 90px;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}
</style>
