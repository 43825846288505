/**
 * Created by Ivan Kubota on 4/12/18.
 * Only the author can use it for commercial purpose right now.
 * You can use email to contact me: zibx@quokka.pub
 */

import Figure from './Figure.js'
import Line from './Primitives/Line.js'
import Color from '../Draw/Color.js';

Figure.Border = function(from, to, cfg) {
    var scale = this.dashScale;
    if(scale){
        this._dash = this._dash.map(function(size) {
            return size*scale;
        });
    }
};

Figure.Border.prototype = {
    _dash: [5,5],
    _points: ['from', 'to'],
    isSegment: true,
    draw: function(ctx, time) {
        ctx.lineWidth = 5;
        if(this.type === 'border-dash'){
            ctx.beginPath();
            ctx.moveTo( this.from.x, this.from.y );
            ctx.lineTo( this.to.x, this.to.y );
            ctx.setLineDash(this._dash);
            ctx.strokeStyle = new Color('#ffdb01');
            ctx.stroke();
            ctx.setLineDash([]);
        }else{
            var color = this.color?this.color:this.solid?
                Color.lerp('#e33d2f','#e3a924', (Math.sin(time*5)+1)/2)
                :
                new Color('#eee');

            /*if(this.figure !== 'finish'){
                color.darken( 0.5 );
            }*/

            ctx.strokeStyle = color;

            ctx.beginPath();
            ctx.moveTo( this.from.x, this.from.y );
            ctx.lineTo( this.to.x, this.to.y );
            ctx.stroke();
        }
    },
    getLine: function() {
        return new Line(this.from, this.to, true);
    },
};

Figure.Border.prototype = Object.assign({}, Line.prototype, Figure.Border.prototype);

Figure.extend('Border');
