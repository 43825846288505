/**
 * Created by Ivan Kubota on 4/12/18.
 * Only the author can use it for commercial purpose right now.
 * You can use email to contact me: zibx@quokka.pub
 */

import Point from './Primitives/Point.js';
import Arc from './Primitives/Arc.js'
// extend Arc model object

Arc.prototype._points = ['center'];
Arc.prototype._scale = ['radius'];
/*Arc.prototype.draw = function(ctx) {
    var color = new Color('#eee')
    if(this.figure !== 'overpass'){
        color.darken( 0.5 );
    }
    R.arc(this, color, this.radius)
};*/
Arc.prototype.clockwise = null;
Arc.prototype.move = function(x, y) {
    if(x instanceof Point){
        y = x.y;
        x = x.x;
    }
    return new Arc({
        center: this.center.addClone(x,y),
        radius: this.radius,
        angle: this.angle,
        clockwise: this.clockwise,
        segment: this.segment,
    })
};
Arc.prototype.rotate = function(angle, origin) {
    if(!origin)
        origin = new Point(0,0);
    return new Arc({
        center: this.center.subClone(origin).rotate(angle).add(origin),
        radius: this.radius,
        angle: this.angle+angle,
        clockwise: this.clockwise,
        segment: this.segment,
    })
};
Arc.prototype.scale = function(scale, origin) {
    if(!origin)
        origin = new Point(0,0);
    return new Arc({
        center: this.center.subClone(origin).mul(scale).add(origin),
        radius: this.radius*scale,
        angle: this.angle,
        clockwise: this.clockwise,
        segment: this.segment,
    })
};

export default Arc;
