/**
 * Created by Ivan Kubota on 4/12/18.
 * Only the author can use it for commercial purpose right now.
 * You can use email to contact me: zibx@quokka.pub
 */

var Collision = function(point, o1, o2) {
    this.point = point;
    this.o1 = o1;
    this.o2 = o2;
};
Collision.prototype = {

};
export default Collision;
/*

segment = 40
test = 10
getAngle = 350

10-350=-340 => 20
 */
