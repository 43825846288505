/**
 * Created by Ivan Kubota on 4/12/18.
 * Only the author can use it for commercial purpose right now.
 * You can use email to contact me: zibx@quokka.pub
 */

import Point from './Point.js';
import Line from './Line.js';
import Arc from './Arc.js';
import {EPS, PI_HALF} from '../../generic.js';

var RoundPath = function(border1, border2, radius,debugSecond) {
	var l1 = new Line(border1.from, border1.to, true),
		l2 = new Line(border2.from, border2.to, true);

	var intersection = l1.intersectLine(l2);

	if(intersection === false)

	var line1angle = border1.from.subClone(border1.to).getAngle();
	var line2angle = border2.from.subClone(border2.to).getAngle();

	var p1, p2, delta, distance;
	p1 = new Point(border1.from);
	p2 = new Point(border1.to);

	var point1, point2, perp1, perp2, point1from = false, point2from = false;

	// move one side nearest point
	if(p1.distance( intersection.point ) < p2.distance( intersection.point )){
		distance = (delta = intersection.point.subClone(p2)).magnitude();
		point1 = border1.from = p2.addClone(delta.div(distance).mul(distance-radius));
		point1from = true;
	}else{
		distance = (delta = p1.subClone(intersection.point)).magnitude();
		point1 = border1.to = p1.subClone(delta.div(distance).mul(distance-radius));
	}
	perp1 = point1.addClone(delta.y, -delta.x);


	p1 = new Point(border2.from);
	p2 = new Point(border2.to);

	// move other side nearest point
	if(p1.distance( intersection.point ) < p2.distance( intersection.point )){
		distance = (delta = intersection.point.subClone(p2)).magnitude();
		point2 = border2.from = p2.addClone(delta.div(distance).mul(distance-radius));
		point2from = true;
	}else{
		distance = (delta = p1.subClone(intersection.point)).magnitude();
		point2 = border2.to = p1.subClone(delta.div(distance).mul(distance-radius));
	}
	perp2 = point2.addClone(delta.y, -delta.x);
	var circleCenter = new Line(perp1, point1, true).intersectLine(new Line(perp2, point2, true));
	var a1 = point2.subClone(circleCenter.point).getAngle();
	var a2 = point1.subClone(circleCenter.point).getAngle();

	var PI = Math.PI;
	var a3 = PI-Math.abs(Math.abs(a1-a2)-PI);

	//var angles = [a1, a3];
	/*var a = function(angl) {
			return (angl/PI*180).toFixed(0)
	}*/

	/*var debugText = '1:'+a(a1)+'\n' +
			'2:'+a(a2)+'\n' +
			'3:'+a(a3) + '|'+(point1from?'1':0)+ (point2from?'1':0)+'\n'+a(line1angle)+'\n'+a(line2angle);

	*/
	/*var debugText = a(angles[0])+' '+a(a3)
			ctx.fillText(debugText, (debugSecond<0?point1:point2).x,(debugSecond<0?point1.y:point2.y+debugSecond*15))
	R.circle(circleCenter.point,border1.color || border2.color || 'red', 2)*/
	var clockwising;
	if(point1from){
		clockwising = Math.abs(line1angle)<=PI_HALF;
	}else{
		clockwising = Math.abs(line1angle)>PI_HALF;
	}

	return [border1, border2, new Arc({
		solid: border1.solid && border2.solid,
		closest: false,
		angle: a1+EPS*5,
		segment: a3+EPS*10,//angles[1],
		clockwise: clockwising?a2>=0:a2<0,//a2>PI/2,
		/*        from: point1,
						to: point2,*/
		center: new Point(circleCenter.point), radius: circleCenter.point.distance(point1),
		//debugText: debugText
	})]
};

export default RoundPath;
