import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/prizes',
    name: 'Prizes',
    component: () => import(/* webpackChunkName: "prizes" */ '../views/Prizes.vue')
  },
  {
    path: '/contextual-help1',
    name: 'ContextualHelp1',
    component: () => import(/* webpackChunkName: "contextualhelp1" */ '../views/ContextualHelp1.vue')
  },
  {
    path: '/contextual-help2',
    name: 'ContextualHelp2',
    component: () => import(/* webpackChunkName: "contextualhelp2" */ '../views/ContextualHelp2.vue')
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue')
  },
  {
    path: '/ar-view',
    name: 'ARView',
    component: () => import(/* webpackChunkName: "arview" */ '../views/ARView.vue')
  },
  {
    path: '/prize',
    name: 'Prize',
    component: () => import(/* webpackChunkName: "prize" */ '../views/Prize.vue')
  },
  {
    path: '/validation',
    name: 'Validation',
    component: () => import(/* webpackChunkName: "validation" */ '../views/Validation.vue')
  },
  {
    path: '/validation2',
    name: 'Validation2',
    component: () => import(/* webpackChunkName: "validation2" */ '../views/Validation2.vue')
  },
  {
    path: '/validated',
    name: 'Validated',
    component: () => import(/* webpackChunkName: "validated" */ '../views/Validated.vue')
  },
  {
    path: '/tutorial1',
    name: 'Tutorial1',
    component: () => import(/* webpackChunkName: "tutorial1" */ '../views/Tutorial1.vue')
  },
  {
    path: '/tutorial2',
    name: 'Tutorial2',
    component: () => import(/* webpackChunkName: "tutorial2" */ '../views/Tutorial2.vue')
  },
  {
    path: '/tutorial3',
    name: 'Tutorial3',
    component: () => import(/* webpackChunkName: "tutorial3" */ '../views/Tutorial3.vue')
  },
  {
    path: '/ar-experience',
    name: 'ArExperience',
    component: () => import(/* webpackChunkName: "ArExperience" */ '../views/ArExperience.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
