/**
 * Created by Ivan Kubota on 4/12/18.
 * Only the author can use it for commercial purpose right now.
 * You can use email to contact me: zibx@quokka.pub
 */

import Figure from './Figure.js'
import Point from './Primitives/Point.js';

Figure.extend = function(nephewName) {
    var oldCtor = Figure[nephewName],
        newCtor = function(arg) {
            var args, cfg,
                points = this._points;


            if( !( this instanceof newCtor ) ){
                return new newCtor(arguments);
            }
            if(Object.prototype.toString.call(arg)!=='[object Arguments]'){
                args = arguments
            }else{
                args = arg;
            }
            if(points){
                var pointName = points[ 0 ],
                    cfg;
                if(pointName in args[0]){
                    // if first object is a config with all other props
                    cfg = args[0];
                }else{
                    cfg = args[points.length] || {};
                    for( var i = 0, _i = points.length; i < _i; i++ ){
                        pointName = points[ i ];
                        cfg[pointName] = args[i];
                    }
                }
                for( var i = 0, _i = points.length; i < _i; i++ ){
                    pointName = points[ i ];
                    cfg[pointName] = new Point(cfg[pointName]);
                }
            }
            Object.assign(this, cfg);
            oldCtor.call(this)
        };
    newCtor.prototype = new Figure.Basic();
    newCtor.prototype.type = nephewName;
    Object.assign(newCtor.prototype, oldCtor.prototype);
    Figure[nephewName] = newCtor;
    return newCtor;
};

