/**
 * Created by Ivan Kubota on 4/12/18.
 * Only the author can use it for commercial purpose right now.
 * You can use email to contact me: zibx@quokka.pub
 */

var Figure = function(name, cfg) {
    return new Figure[name]();
};
Figure.define = function(name, fnCfg) {
    Figure[name] = function(cfg) {
        var result = fnCfg(cfg);
        if(result instanceof Figure.start)
            result = result.items;
        return [name].concat(Figure.flat(result));
    }
};
Figure.flat = function(arr ) {
    return [].concat.apply([],arr)
};

var F = Figure;

export default Figure;

