var  Lerp = function(a, b, t){
  return a + (b - a) * t
};

var keyLoader = {
  load: function() {
    var modelFileName = 'Key_F_2.glb',
      lines = window.keyLines = {
        'Point':    { from: 50, to: 370, start: 0, loop: false, opacity: 10},
        'Birdoak1_': { from: 0, to: 400, start: 30, opacity: 40 },//+
        'Birdoak2': { from: 0, to: 400, start: 100, opacity: 100 },//+
        'Birdoak3': { from: 0, to: 400, start: 50, opacity: 60 },//+
        'Birdoak4': { from: 0, to: 400, start: 150, opacity: 30 },
      },
      sceneAnimationLength = 480,
      objects = {
        'Platform_Oclusion': {
          fn: function( child ){
            child.material.colorWrite = false
            child.renderOrder = 0;

            child.rotateY(Math.PI);
            child.scale.x = child.scale.y = child.scale.z = 0.8;
            child.material.transparent = true;
            child.transparent = true;
            child.opacity = 0.5;
            child.material.opacity = 0.5;
          }
        },
      };
    this.keyScene = new THREE.Scene();
    this.key = initScene({
      scene: this.keyScene,
      camera: this.ctx.camera,
      renderer: this.ctx.renderer,
      modelFileName,
      objects,
      lines,
      sceneAnimationLength,
      loadCb: function() {
        for(var i = 28;i < 38; i++){
          var c = ARController.key.scene.children[1].children[i].scale;
          c.x = c.y = c.z = 0.8;
          ARController.key.scene.children[1].children[i].rotateY(Math.PI);
        }
        store.set('sceneKeyLoaded', true);
      }
    });
  },
  render: function(T, RT, key) {


    RT = T = RT - 6800;

    key.visible = T>0;
    if(T<0){
      return;
    }

    T = Math.min(T, 3999);

    var s0 = ARController.plane2.scale.x;
    store.set('keyDirection', RT>3000)
    var bezier = {
      from: {
        pos: new THREE.Vector3( 0, 0, s0 * 2.065 ),

      },
      middle: {
        pos: new THREE.Vector3( s0*-0.8, s0 * -0.6, s0 * 0.75 )
      },
      to: {
        pos: new THREE.Vector3( s0*-2.75, s0 * -6.5, s0 * 1.85 )
      }
    };
    if(RT>3500){
      var bezierT = Math.min((RT-3500)/5000,1);

      var v = bezier.from.pos.lerp(bezier.middle.pos, bezierT).lerp( bezier.middle.pos.lerp(bezier.to.pos, bezierT), bezierT);
      if(RT>7500){
        var anim = ( RT - 7500 ) / 4000;
        if( anim > 1 ){
          anim = 1;
        }
        //var v = new THREE.Vector3( 0, Lerp( s0 * -0.6, s0 * -4.5, anim ), Lerp( s0 * 0.35, s0 * 0.35, anim ) );
        var s = Lerp( s0 / 3 /*1.41*/, s0 / 1.2, anim );
        Object.assign( key.quaternion, ARController.plane2.quaternion );
        key.rotateX( Math.PI / 2 * ( 1 - 1 ) )
        key.rotateY( Math.PI / 2 )
        key.rotateX( 0.24+0.27 *anim )

      }else{
        var anim = ( RT - 3500 ) / 4000;
        if( anim > 1 ){
          anim = 1;
        }
        //var v = new THREE.Vector3( 0, Lerp( 0, s0 * -0.6, anim ), Lerp( s0 * 2.545, s0 * 0.35, anim ) );
        var s = Lerp( s0 / 1.55 /*1.41*/, s0 / 3, anim );
        Object.assign( key.quaternion, ARController.plane2.quaternion );
        key.rotateX( Math.PI / 2 * ( 1 - anim ) )
        key.rotateY( -Math.PI / 2 * anim )
        key.rotateY( Math.PI )
        key.rotateX( 0.24 *anim )

        //key.rotateX( 0.34 * anim )
      }
    }else{
      var v = new THREE.Vector3(0,0,s0*2.065/*2.445*/);
      var s = s0/1.55;
      Object.assign(key.quaternion, ARController.plane2.quaternion);
      key.rotateX(Math.PI/2)
      key.rotateY(Math.PI)
      // key.rotateZ(Math.PI/6)
      //key.rotateX(ARController.keyRotation)
      ;
    }
    v.applyEuler(ARController.plane2.rotation);

    key.material.opacity = T;


    Object.assign(key.scale,{x:s,y:s,z:s});
    //Object.assign(key.position, point);Object.assign(key.position, point);


    Object.assign(ARController.standaloneKey.position, new THREE.Vector3())
    Object.assign(key.position, ARController.plane2.position);
    key.position.add(v)
    var renderer = ARController.ctx.renderer;


    renderer.clear(false, true, true);
    ARController.ctx.renderer.render( ARController.standaloneKey, ARController.ctx.camera );
    renderer.autoClear = false;
  },
  position: function(plane1, plane2, sceneControl) {
    Object.assign(sceneControl.group.quaternion, plane2.quaternion)
    sceneControl.group.rotateX(Math.PI/2)
    sceneControl.group.scale.x = sceneControl.group.scale.y = sceneControl.group.scale.z =  plane2.scale.x/131*104;
    Object.assign(sceneControl.group.position, plane2.position);
    window.positionVirtualCam(plane1, plane2, ARController.ctx.camera)
  },
  loadKey2: function() {
    var modelFileName = 'MainSceneKey.glb',
      lines = {
        'Point':    { from: 0, to: 370, start: 0, loop: false, opacity: 30},
        'Birdoak10':{ from: 0, to: 300, start: 0, opacity: 320 },
        'Birdoak1_': { from: 0, to: 300, start: 100, opacity: 120 },
        'Birdoak2': { from: 0, to: 330, start: 0, opacity: 100 },
        'Birdoak3': { from: 0, to: 300, start: 30, opacity: 50 },
        'Birdoak4': { from: 0, to: 300, start: 10, opacity: 30 },
        'Birdoak5': { from: 0, to: 300, start: 0, opacity: 160 },
        'Birdoak6': { from: 0, to: 300, start: 150, opacity: 180 },
        'Birdoak7': { from: 0, to: 300, start: 200, opacity: 214 },
        'Birdloak8': { from: 0, to: 330, start: 200, opacity: 241 },
        'Birdoak9': { from: 0, to: 300, start: 0, opacity: 84 },
      },
      objects = {
        'KeyLP001': {
          step: function(time) {
            if(!this.newMaterial)
              return;
            this.newMaterial.opacity = time;
            this.obj.material.opacity = time;
          },
          fn: function(child) {
            this.fn = apearingChild(272,370);
            this.fn(child)
          }
        }
      },
      sceneAnimationLength = 480;

    this.keyScene = new THREE.Scene();
    this.key = initScene({
      scene: this.keyScene,
      camera: this.ctx.camera,
      renderer: this.ctx.renderer,
      modelFileName,
      objects,
      lines,
      sceneAnimationLength,
      loadCb: function() {
        store.set('sceneKeyLoaded', true);
      }
    });
  },
};

export default keyLoader;
