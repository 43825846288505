import KeyLoader from './loadKey.js';
import StateController from '../StateController.js';
import DoorLoader from './loadDoor.js';
import SphereLoader from './loadSphere.js';
import Point from "../Tapir2D/Objects/Primitives/Point.js";
import LinesAnimator from "./LinesAnimator.js";
import PrizeController from "./PrizeController.js";
import keyLoader from "./loadKey.js";
var overlayImage = new Image();
overlayImage.src = '/images/overlay.png';

const ARController = window.ARController = {
  R,
  KeyLoader,
  DoorLoader,
  SphereLoader,
  LinesAnimator,
  PrizeController,
  setCookie: function(name,value,days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days*24*60*60*1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  },
  getCookie: function(name) {
/*    if(name === 'tutorial')
      return null;*/

    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
      var c = ca[i];
      while (c.charAt(0)==' ') c = c.substring(1,c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  },


  eraseCookie: function(name) {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  },
  initSecret: async function() {
    var item = ARController.getCookie('_aaftr_lot');
    if(item){
      ARController.secret = JSON.parse( item );
    }else{
      ARController.secret = item = await Ajax.async.post('/api/getSecret', {info: [navigator.userAgent, window.outerWidth,window.outerHeight, window.innerWidth, window.innerHeight]})
      ARController.setCookie('_aaftr_lot', JSON.stringify(item), 7);
    }
  },
  init: function() {
    setTimeout(ARController.initSecret, 300);
    setTimeout(ARController.CameraLogics, 300);
    setTimeout(ARController.PrizeController.init, 300);
    /*window.addResizeCallback(function() {
      D('.orientation-warning')[0].classList.toggle('hidden', window.innerWidth<window.innerHeight);
    });*/
    var _self = this;
    var c1 = document.getElementById('c1');
    var c2 = document.getElementById('c2');
    var c3 = document.getElementById('c3');

    var firstCall = true;
    StateController.init(c1,c2, this,c3);
    store.sub(['XR', 'ar'], function(XR, ar) {
      if(!XR)
        return;

      if(ar){
        c1.style.display = 'block';
        ARController.andResumeIt();
      }else{
        c1.style.display = 'none';
        ARController.andPauseIt();
      }
    });

    store.sub(['sceneKeyLoaded', 'sceneDoorLoaded'], function(a, b) {
      store.set('scenesLoaded', a && b)
    });

    store.sub(['scannedQR'], function(qr) {

      var sceneControl = ARController.getActiveScene();
      if(sceneControl){
        sceneControl.show()
        console.log('setTime1', 0, sceneControl.getTime())
        sceneControl.setTime(0);
        setTimeout(function() {
          console.log('setTime1~', 0)
          sceneControl.setTime(0, sceneControl.getTime());
        }, 10);

      }
    });
    D.div({
      cls: 'stats',
      renderTo: document.body,
      style: {
        display: 'none',
        fontFamily: 'monospace',
        fontSize: '9px',
        position: 'absolute',
        zIndex: 7,
        right: 0,
        top: '20%',
        padding: '4px 8px',
        background: 'rgba(0,0,0,0.8)',
        color: '#fff',
        opacity: 0.5,
        pointerEvents: 'none',

      }
    },
      D.div({}, D.span({},'keyLoaded'), store.val('sceneKeyLoaded')),
      D.div({}, D.span({},'doorLoaded'), store.val('sceneDoorLoaded')),
      D.div({}, D.span({},'ar'), store.val('ar')),
      D.div({}, D.span({},'time'), store.val('timeFromStart')),
      D.div({}, D.span({}, 'distance'), store.val('distance')),
      D.div({}, D.span({}, 'scene'), store.val('scannedQR')),
      D.div({}, D.span({}, 'lost'), store.val('lost')),
      D.div({}, D.span({}, 'inSphere'), store.val('inSphere'))
    );
  },
  stopScene: function() {
    var active = ARController.getActiveScene();
    if( active ){
      console.log('setTime2, stop', 0, active.getTime())
      active.setTime( 0, 'animate, stop' );
      active.animate();
      active.stop();
    }
    store.set( 'ar', false );
  },
  lastName: false,
  setInSphere: function(val) {
    ARController.inSphere = val;
    store.set('inSphere', val);
  },
  inSphere: false,
  getActiveSceneName: function(){
    var name = store.get('scannedQR')+'',
        result = false;
    if(name.indexOf('key') > -1){
      result = 'key';
    }else if(name.indexOf('door') > -1){

      result = 'door';
    }
    this.lastName = result;
    return result;
  },
  getActiveScene: function() {
    var name = ARController.getActiveSceneName();

    if(name)
      return this[name];

    return false;
  },
  scenePosition: function(plane1, plane2, sceneControl) {
    var sceneName = this.getActiveSceneName();
    if(!sceneName)
      return;

    if(sceneName === 'key'){
      KeyLoader.position.call(this, plane1, plane2, sceneControl)
    }else if(sceneName === 'door' || sceneName === 'sphere'){
      DoorLoader.position.call(this, plane1, plane2, sceneControl);
      SphereLoader.position.call(this, plane1, plane2, sceneControl);
    }
  },
  takeAPhoto: function() {
    XR8.CanvasScreenshot.takeScreenshot({
      onProcessFrame: function(ctx,b,c) {
          var ctxW = ctx.canvas.width-0,
          ctxH = ctx.canvas.height-0;

        var p = [ctxW, ctxH];

        var h0 = p[0]/767*300|0,
          h1 = p[1]-p[0]/767*300|0;

        ctx.ctx.drawImage(overlayImage,0,0,767,300,0,0,p[0],h0);
        ctx.ctx.drawImage(overlayImage, 0,1661-300,  767,300, 0,h1,p[0],p[1]-h1);
        ctx.ctx.drawImage(overlayImage, 0,301,  767,1661-600, 0,h0, p[0],h1-h0);

      }
    }).then(function(data){
      fetch('data:image/jpeg;base64,' + data)
        .then(res => res.blob())
        .then(blob=>{
          const a = document.createElement('a')
          a.href = window.URL.createObjectURL(blob)
          a.download = 'HM_Photo_'+Math.random().toString(36).substr(2)+'.jpg'
          a.click()
        });
    }, (error)=>{})
  },
  recordVideo: function() {
    var firstCall = true, ctxW = 0, ctxH = 0,
        overlay;
    XR8.MediaRecorder.recordVideo({
      onVideoReady: (result) =>{

        /*if(ARController.gifLoader && ARController.gifLoader.parentNode){
          ARController.gifLoader.parentNode.removeChild( ARController.gifLoader );
        }*/
        D('.camera-controls__button')[0].classList.remove('saving-video');

        const a = document.createElement('a')
            a.href = window.URL.createObjectURL(result.videoBlob)
            a.download = 'HM_Video_'+Math.random().toString(36).substr(2)+'.mp4'
            a.click()


        debugger; window.dispatchEvent(new CustomEvent('recordercomplete', {detail: result}));
        },
      //onStop: () => showLoading(),
      //onError: () => clearState(),
      onProcessFrame: ({elapsedTimeMs, maxRecordingMs, ctx}) => {
        if(firstCall){
          firstCall = false;
          ctxW = ctx.canvas.width-0;
          ctxH = ctx.canvas.height-0;
          overlay = document.createElement('canvas');

          var octx = overlay.getContext('2d');
          overlay.width = ctxW;
          overlay.height = ctxH;
          octx.clearRect(0,0,ctxW,ctxH);

          var p = [ctxW, ctxH];

          var h0 = p[0]/767*300|0,
            h1 = p[1]-p[0]/767*300|0;

          octx.drawImage(overlayImage,0,0,767,300,0,0,p[0],h0);
          octx.drawImage(overlayImage, 0,1661-300,  767,300, 0,h1,p[0],p[1]-h1);
          octx.drawImage(overlayImage, 0,301,  767,1661-600, 0,h0, p[0],h1-h0);



        }

        ctx.drawImage(overlay,0,0);


        // overlay some red text over the video
        /*ctx.fillStyle = 'red'
        ctx.font = '50px "Nunito"'
        ctx.fillText(`${elapsedTimeMs}/${maxRecordingMs}`, 50, 50)
        const timeLeft =  ( 1 - elapsedTimeMs / maxRecordingMs)*/
        // update the progress bar to show how much time is left
        //progressBar.style.strokeDashoffset = `${100 * timeLeft }`


      },
      onFinalizeProgress: ({progress, total}) => {
        console.log('Export is ' + Math.round(progress / total) + '% complete')
      },
    })

  },
  stopRecording: function() {
    D('.camera-controls__button')[0].classList.add('saving-video');

    /*if(ARController.gifLoader && ARController.gifLoader.parentNode){
      ARController.gifLoader.parentNode.removeChild( ARController.gifLoader );
    }

    ARController.gifLoader = D.h('img', {src:'/images/ajax-loader.gif', cls: 'gif-loader', renderTo: document.body});*/
    XR8.MediaRecorder.stopRecording();

  },
  initScene: function(ctx) {
    var {scene, camera, renderer} = ctx;
    this.ctx = ctx;

    window.addResizeCallback(() => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize( window.innerWidth, window.innerHeight );
      //render();
    }, false );

    this.loadKey();
    this.loadDoor();
    this.loadSphere();
  },

  render: function(dt) {
    if(!store.get('scenesLoaded'))
      return;
    var active = this.getActiveScene();
    active && active.animate(dt);

  },
  getSceneTime: function() {
    var active = this.getActiveScene();
    return active && active.getTime();
  },
  thePipelineRenderer: {
    name: 'ARController',
    onRender: function() {
      var active = ARController.getActiveScene();
      if(active){
        if(ARController.lastName === 'key'){
          ARController.ctx.renderer.render( active.scene, ARController.ctx.camera );

          if(!ARController.standaloneKey)
            return;

          var key = ARController.standaloneKey.children[1];
          if(!key)
            return;

          if(!keyVisible){
            keyVisible = true;
            lastT = +new Date() - 1;
            T = 0; RT = 0;
          }
          var t = +new Date();
          T+=t-lastT;
          RT+=t-lastT;
          lastT = t;

          KeyLoader.render(T, active.getTime()*1000, key);

        }else{
          if(ARController.inSphere){
            SphereLoader.render();
          }else{
            ARController.ctx.renderer.render( active.scene, ARController.ctx.camera );
          }
        }
      }
    }
  },
  loadKey: function() {
    KeyLoader.load.call(this);
  },
  loadDoor: function() {
    DoorLoader.load.call(this);
  },
  loadSphere: function() {
    SphereLoader.load.call(this);
  },
  _resumeTimeout: function() {
    if(!store.get('ar'))
      return;
    XR8.resume();
    if(XR8.isPaused())
      setTimeout(ARController._resumeTimeout, 400);
  },
  andResumeIt: function() {
    clearTimeout(this.pauseTimeout);
    clearTimeout(this.resumeTimeout);
    this.resumeTimeout = setTimeout(ARController._resumeTimeout, 100);
  },
  _andPauseIt: function() {
    if(store.get('ar'))
      return;
    XR8.pause();
    if(!XR8.isPaused())
      setTimeout(ARController._andPauseIt, 400);
  },
  andPauseIt: function() {
    clearTimeout(this.pauseTimeout);
    clearTimeout(this.resumeTimeout);
    this.pauseTimeout = setTimeout(ARController._andPauseIt, 100);
  },
  keyRotation: 0,
  lastDebounce: function(fn, dt) {
    var timeout = false, args, scope,
      realCall = function() {
        timeout = false;
        fn.apply(scope, args)
      };
    return function(){
      args = [].slice.call(arguments);
      scope = this;
      if(timeout){
        clearTimeout(timeout);
      }
      timeout = setTimeout(realCall, dt);
    }
  },

  makeVeryOverlay: function() {
    ARController.hideVeryOverlay();
    window.veryOverlay = D.div({cls: 'very-overlay'});
    var app = D('#app')[0];
    app.parentNode.insertBefore(window.veryOverlay, app);
  },
  hideVeryOverlay: function() {
    if(window.veryOverlay &&  window.veryOverlay.parentNode){
      window.veryOverlay.parentNode.removeChild(window.veryOverlay);
      window.veryOverlay = false;
    }
  },

  CameraLogics: function() {
    this.scanSub2 = store.sub(['scannedQR', 'scenesLoaded', 'attempt'], (scanned, loaded, attempt) => {
      if(scanned === 'door')
        store.set('doorScanned', true);
      if(!loaded)
        return;
      //ARController.inSphere = false;
      var active = ARController.getActiveScene();
      //active && active.setTime(0);
      if(scanned === 'door'){
        store.set('mapShown', false);
        //store.set('centerButton', 'record');
      }
    });
    var lastScene;// = store.get('scannedQR');
    var leaved = false;

    var nothingToShow = Store.debounce(function() {

    }, 3000);

    store.sub(['ar', 'scannedQR', 'doorScanned', 'lastScannedDate', 'mapShown' ], function(
      ar, scannedQR, doorScanned, lastScannedDate, mapShown
    ) {
      var lost = false;
      if(ar && !scannedQR){
          lost = true;
      }
/*      if(doorScanned){
        lost = false
      }*/
      if(mapShown){
        lost = false;
      }
      store.set('lost', lost);
    });
    this.scanSub = store.sub(['scenesLoaded', 'scannedQR', 'distance', 'timeFromStart'], (loaded, scanned, distance, timeFromStart) => {
      if(!loaded)
        return;


      /*if(!scanned && !store.get('doorScanned')){
        if(!store.get('doorScanned'){

        })
      }*/
      //this.scanFrame = !scanned;
      var sceneName = ARController.getActiveSceneName();

      if(sceneName === 'key'){
        if(lastScene !== 'key'){
          var active = ARController.getActiveScene();
          if(active){
            console.log('setTime5', 'key', 0, active.getTime());
            active.setTime(0);
          }
          console.log('setTime7', 'key', 0, ARController.key.getTime());
          ARController.key.setTime(0);
          timeFromStart = 0;

        }
        if( (timeFromStart > 30 && distance > 6) || timeFromStart > 45){
          store.set({
            mapShown: true,
            //centerButton: 'qr'
          });
          //ARController.stopScene();
        }
      }else if(sceneName === 'door'){
        if(lastScene !== 'door'){
          ARController.setInSphere(false);
          var active = ARController.getActiveScene();
          if(active){
            console.log('setTime6', 'door if active', 0, active.getTime());
            active.setTime( 0 );
          }
          console.log('setTime6', 'door', 0, ARController.door.getTime());
          ARController.door.setTime(0);
          timeFromStart = 0;
          leaved = false;
        }

        store.set('enterTheRoomButton', timeFromStart > 14);
        /*if( timeFromStart > 20  ){
          ARController.setInSphere(true);

          if( timeFromStart > 26 ){
            //ARController.stopScene();
            if(!leaved){
              location.replace( '/#/ar-view' );
              leaved = true;
            }
          }
        }else{
          ARController.setInSphere(false);
        }*/
      }
      lastScene = sceneName;
    });
  }
};

window.positionVirtualCam = function(plane1, plane2, camera){

  var p = plane1.position.clone();
  var v = new THREE.Vector3(0,0,1);
  v.applyEuler(plane1.rotation);
  var mainAngle = Math.atan2(v.x,v.z)

  p = camera.position.clone();
  v = new THREE.Vector3(0,0,1);
  v.applyEuler(camera.rotation);
  var camAngle = -Math.atan2(v.x,v.z)+mainAngle;

  var kh = (new Point(camera.position.x-plane1.position.x,camera.position.z-plane1.position.z)).div(plane1.scale.x/10)
  cam.angle = camAngle; //var dist =  ARController.ctx.camera.position.distanceTo(plane1.position)/plane1.scale.x*10;
  Object.assign(cam.center, kh)
}



var lastT, dt, keyVisible = false, T = 0, RT = 0;
export default ARController;
