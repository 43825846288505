// var ax = new THREE.Vector3(0,0,1),ax2 = new THREE.Vector3(0,0,1);

var loader = window.sphereLoader = module.exports = {
  loaded: false,
  position: function(plane1, plane2, sceneControl) {
    if(loader.loaded){
/*      Object.assign( ARController.sphere.group.position, ARController.ctx.camera.position );
      ARController.sphere.group.scale.x =
        ARController.sphere.group.scale.y =
          ARController.sphere.group.scale.z = plane1.scale.x;*/

      //Object.assign(ARController.sphere.quaternion, plane2.quaternion);
    }
  },
  render: function() {
    /*Object.assign(loader.cameraClone.position, ARController.ctx.camera.position );
    Object.assign(loader.cameraClone.quaternion, ARController.ctx.camera.quaternion );*/
    /*loader.cameraClone.rotateOnAxis(ax, Math.PI)
    loader.cameraClone.rotateZ(Math.PI)*/
    ARController.ctx.renderer.render( ARController.sphere.scene, ARController.ctx.camera);//loader.cameraClone);//ARController.ctx.camera );
  },
  load: function(){
    loader.cameraClone = ARController.ctx.camera.clone();
    var sphereScene = this.sphereScene = new THREE.Scene();
    {
      ( new THREE.TextureLoader() ).load(
        'Room_dfilled_4k_optimized.jpg',
        ( texture ) => {
          const rt = new THREE.WebGLCubeRenderTarget( texture.image.height );
          rt.fromEquirectangularTexture( ARController.ctx.renderer, texture );
          ARController.sphereScene.background = rt.texture;
          loader.loaded = true;
          console.info('sphere texture loaded');

          /*var material = new THREE.MeshBasicMaterial({
            map: rt.texture,
            side: THREE.DoubleSide
          });
          var sphere = new THREE.Mesh(new THREE.BoxGeometry(5, 5, 5), material);
          sphereScene.add(sphere)
          ARController.group = sphere;*/
        } );

    }
    this.sphereScene.add(new THREE.AmbientLight(0x404040, 5));


    /*var material = new THREE.MeshBasicMaterial({
      map: THREE.ImageUtils.loadTexture('Room_dfilled_2k.jpg', function ( texture ) {

      }),
      side: THREE.DoubleSide
    });
    material.side = THREE.doubleSided;
    var sphere = new THREE.Mesh(new THREE.SphereGeometry(10, 32, 16,  0, Math.PI * 2, 0, Math.PI * 2), material);
    sphere.renderOrder = 2;
    sphere.doubleSided = true;
    this.sphereScene.add(sphere);*/

    var TIME = 0, animateScene = true;

    this.sphere = {
      scene: this.sphereScene,

      camera: this.ctx.camera,
      renderer: this.ctx.renderer,
      animate: function() {

      },
      show: function(){
        animateScene = true;
      },
      stop: function(){
        animateScene = false;
      },
      setTime: function( time ){
        TIME = time;
      },
      getTime: function(){
        return TIME;
      }
    };
  }
};
