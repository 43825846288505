/**
 * Created by Ivan Kubota on 4/12/18.
 * Only the author can use it for commercial purpose right now.
 * You can use email to contact me: zibx@quokka.pub
 */

import Point from './Point.js';
import Vector from './Vector.js';
import Collision from './Collision.js';
import {EPS} from '../../generic.js';
var Line = function(from, to, isNotSegment) {
    if('from' in from){
        /*this.from = from.from;
        this.to = from.to;*/
        Object.assign(this, from);
    }else{
        this.from = from;
        this.to = to;
    }
    if(isNotSegment === true)
        this.isSegment = false;

};
Line.prototype = {
/*    move: function(x, y) {
        if(x instanceof Point){
            y = x.y;
            x = x.x;
        }
        return new Line(this.from.addClone(x,y), this.to.addClone(x,y), !this.isSegment);
    },*/
    rotate: function(angle, origin) {
        if(!origin)
            origin = new Point(0,0);
        return new Line(
            this.from
                .subClone(origin)
                .rotate(angle)
                .add(origin),
            this.to
                .subClone(origin)
                .rotate(angle)
                .add(origin),
            !this.isSegment);
    },
    scale: function(scale, origin) {
        if(!origin)
            origin = new Point(0,0);
        return new Line(
            this.from
                .subClone(origin)
                .mul(scale)
                .add(origin),
            this.to
                .subClone(origin)
                .mul(scale)
                .add(origin),
            !this.isSegment);
    },

    isSegment: true,
    intersect: function(other) {
        return other.intersectLine(this);
    },
    intersectArc: function(arc){
        return arc.intersectLine(this);
    },
    intersectLine: function(l1) {

       // if(this.color==='green')debugger;

        var l2 = this;

        var l1_dy = l1.to.y - l1.from.y,
            l1_dx = l1.from.x - l1.to.x,

            l2_dy = l2.to.y - l2.from.y,
            l2_dx = l2.from.x - l2.to.x,

            det = l1_dy * l2_dx - l2_dy * l1_dx;
        if (det > -0.0002 && det < 0.0002) {
            return false;
        }

        var l1_min_x = Math.min(l1.from.x,l1.to.x)-EPS,
            l1_max_x = Math.max(l1.from.x,l1.to.x)+EPS,

            l1_min_y = Math.min(l1.from.y,l1.to.y)-EPS,
            l1_max_y = Math.max(l1.from.y,l1.to.y)+EPS,


            l2_min_x = Math.min(l2.from.x,l2.to.x)-EPS,
            l2_max_x = Math.max(l2.from.x,l2.to.x)+EPS,

            l2_min_y = Math.min(l2.from.y,l2.to.y)-EPS,
            l2_max_y = Math.max(l2.from.y,l2.to.y)+EPS;

        if(l1.isSegment && l2.isSegment){
            if( l2_max_x < l1_min_x || l2_min_x > l1_max_x || l2_max_y < l1_min_y || l2_min_y > l1_max_y ){
                return false;
            }
        }

        var c1 = l1_dy * l1.from.x + l1_dx * l1.from.y,
            c2 = l2_dy * l2.from.x + l2_dx * l2.from.y;

        var p = new Point((l2_dx * c1 - l1_dx * c2) / det, (l1_dy * c2 - l2_dy * c1) / det);
        if(l1.isSegment){
            if(
                p.x<l1_min_x || p.x >l1_max_x ||
                p.y<l1_min_y || p.y >l1_max_y
            )
                return false;
        }

        if(l2.isSegment){
            if(
                p.x<l2_min_x || p.x >l2_max_x ||
                p.y<l2_min_y || p.y >l2_max_y
            )
                return false;
        }

        return new Collision(p, l1, this);
    },
    rectContainsPoint: function(point) {
        if(!this.isSegment)
            return true;

        var this_min_x = Math.min(this.from.x,this.to.x)-EPS,
            this_max_x = Math.max(this.from.x,this.to.x)+EPS,

            this_min_y = Math.min(this.from.y,this.to.y)-EPS,
            this_max_y = Math.max(this.from.y,this.to.y)+EPS;
        return !(point.x<this_min_x || point.x >this_max_x ||
            point.y<this_min_y || point.y >this_max_y);
    },
    draw: function() {
        R.line({from:this.from, to:this.to}, this.color || '#05425f', this.width || 2);
    },
    path: function(ctx) {
        //ctx.moveTo(this.from.x, this.from.y);
        ctx.lineTo(this.to.x, this.to.y);
    },
    pathReverse: function(ctx) {
        //ctx.moveTo(this.from.x, this.from.y);
        ctx.lineTo(this.to.x, this.to.y);
        /*ctx.moveTo(this.to.x, this.to.y);
        ctx.lineTo(this.from.x, this.from.y);*/
    },
    length: function() {
        return this.to.distance(this.from);
    },
    clone: function() {
        return new Line(this.from.clone(), this.to.clone())
    },
    getPathPoint: function(percent) {
        return this.to.subClone(this.from).mul(percent).add(this.from);
    },
    move: function(x, y) {
        if(!(x instanceof Point)){
            x = new Point(x,y);
        }
        this.from.add(x);
        this.to.add(x);
        return this;
    },
    getNearestPoint: function(point) {
        var p = new Vector(point.subClone(this.from).projection(this.to.subClone(this.from)));
        var angle = p.angle = p.getAngle();
        p.add(this.from);
        p.percent = p.distance(this.from)/this.length();
        if(this.isSegment){
            if(!this.rectContainsPoint(p)){
                if(this.from.distancePow2(point)<this.to.distancePow2(point)){
                    p = new Vector(this.from);
                    p.percent = 0;
                    p.angle = angle;
                }else{
                    p = new Vector(this.to);
                    p.percent = 0;
                    p.angle = angle;
                }
            }
        }
        return p;
    }
};

export default Line;
