<template>
<!--  <div class="cookies-warning" :class="[cookiesWarningVisible ? 'show' : 'hide']">-->
<!--    <div class="cookies-warning__overlay"></div>-->
<!--    <div class="cookies-warning__dialog">-->
<!--      <div class="cookies-warning__message">-->
<!--        <p>We&nbsp;only use web cookies and similar technologies that are necessary to&nbsp;enable this feature. Read more about how we&nbsp;use cookies and how we&nbsp;process personal data in&nbsp;our Privacy Policy.</p>-->
<!--      </div>-->
<!--      <div class="cookies-warning__buttons">-->
<!--        <Button type="button" value="decline" pattern="secondary" @click="cookiesWarningVisible = false" />-->
<!--        <Button type="button" value="accept" @click="cookiesWarningVisible = false" />-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</template>

<script>
import Button from '@/components/Button.vue'

export default {
  name: 'CookiesWarning',
  components: {
    Button
  },
  data () {
    return {
      cookiesWarningVisible: true
    }
  }
}
</script>

<style lang="scss">
@import "./../assets/styles/variables.scss";
.cookies-warning {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px;
  z-index: 1000;
  transition: opacity 0.3s ease;

  &.hide {
    opacity: 0;
    pointer-events: none;
  }
}

.cookies-warning__overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(#1F1F1F, 0.8);
}

.cookies-warning__dialog {
  position: relative;
  box-sizing: border-box;
  padding: 45px 30px 20px;
  width: 100%;
  background-color: $background-color;
  border-radius: 6px;
  z-index: 1;
}

.cookies-warning__message {
  margin-bottom: 30px;
  text-align: center;
  font-size: 14px;
  line-height: 1.4;
  p {
    margin: 0 0 1em;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.cookies-warning__buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  & > * {
    margin: 0 5px 10px;
  }
}
</style>
