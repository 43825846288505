/**
 * Created by Ivan Kubota on 4/12/18.
 * Only the author can use it for commercial purpose right now.
 * You can use email to contact me: zibx@quokka.pub
 */

import Figure from './Figure.js'
import Point from './Primitives/Point.js'

Figure.Camera = function(from, to, cfg) {

};

Figure.Camera.prototype = {
    _points: ['center'],
    angle: 0,
    draw: function(ctx, time) {
        ctx.rotate(this.angle, ()=> {
            ctx.lineWidth = 1;

            ctx.strokeStyle = '#17bca3';
            ctx.strokeRect(this.center.x - 3, this.center.y-2, 6, 4)
            ctx.beginPath();

            ctx.moveTo( this.center.x, this.center.y );

            var np = this.center.clone(), vec = new Point(8,0).rotate(Math.PI*2/3);
            np.add(vec = vec.rotate(Math.PI*2/3))
            ctx.lineTo( np.x, np.y );

            np.add(vec = vec.rotate(Math.PI*2/3))
            ctx.lineTo( np.x, np.y );

            np.add(vec = vec.rotate(Math.PI*2/3))
            ctx.lineTo( np.x, np.y );

            ctx.stroke();
            ctx.closePath();

        }, this.center)


    },
};


Figure.extend('Camera');
