/**
 * Created by Ivan Kubota on 4/12/18.
 * Only the author can use it for commercial purpose right now.
 * You can use email to contact me: zibx@quokka.pub
 */

import Point from './Objects/Primitives/Point.js';

var RenderTools = function (ctx, donor) {
    this.ctx = ctx;
    this.donor = donor;
};
RenderTools.prototype = {
    line: function ( cfg, color, width) {
        this.ctx.beginPath();
        this.ctx.moveTo(cfg.from.x, cfg.from.y);
        this.ctx.lineTo(cfg.to.x, cfg.to.y);
        this.ctx.lineWidth = width || 5;
        this.ctx.strokeStyle = color;
        this.ctx.stroke();
    },
    lineTo: function(to) {
        this.ctx.lineTo(to.x, to.y);
    },
    moveTo: function(to) {
        this.ctx.moveTo(to.x, to.y);
    },
    vector: function(cfg, color, width) {
        this.line(cfg, color, width);
        var vec = cfg.to.subClone(cfg.from);
        var cap1 = cfg.to.addClone(
            new Point(0,cfg.cap || 15).rotate(vec.getAngle()+Math.PI/3)
        );
        var cap2 = cfg.to.addClone(
            new Point(0,cfg.cap || 15).rotate(vec.getAngle()+Math.PI/3*2)
        );
        this.line({to: cfg.to, from: cap1}, color, width);
        this.line({to: cfg.to, from: cap2}, color, width);
    },
    dot: function(cfg, color) {
        this.ctx.strokeStyle = color || '#f00';
        this.ctx.lineWidth = 1;

        this.ctx.beginPath();
        this.ctx.moveTo(cfg.x-0.1, cfg.y-0.1);
        this.ctx.lineTo(cfg.x+0.1, cfg.y+0.1);
        this.ctx.stroke();
        this.ctx.beginPath();
        this.ctx.moveTo(cfg.x-0.1, cfg.y+0.1);
        this.ctx.lineTo(cfg.x+0.1, cfg.y-0.1);
        this.ctx.stroke();
    },
    rect: function(cfg, color, width) {
        this.ctx.strokeStyle = color || '#f00';
        this.ctx.lineWidth = width || 1;
        this.ctx.strokeRect((cfg.x|0)+0.5, (cfg.y|0)+0.5, cfg.width|0, cfg.height|0);
    },
    circle: function(cfg, color, r) {
        this.ctx.strokeStyle = color || '#f00';
        this.ctx.beginPath();
        this.ctx.arc( cfg.x, cfg.y, r || 3, 0, 2 * Math.PI, false);
        this.ctx.stroke();
    },
    fillCircle: function(cfg, color, r) {
        this.ctx.fillStyle = color || '#f00';
        this.ctx.beginPath();
        this.ctx.arc( cfg.x, cfg.y, r || 3, 0, 2 * Math.PI, false);
        this.ctx.fill();
    },
    arc: function(cfg, color, r) {
        this.ctx.strokeStyle = color || '#f00';

        this.ctx.beginPath();
        var a1 = cfg.from.subClone(cfg.center).getAngle();
        var a2 = cfg.to.subClone(cfg.center).getAngle();
        var clockwise = false;
        if(cfg.closest){
            if((a2-a1+Math.PI*20)%(Math.PI*2)>Math.PI)
                clockwise = true;
        }
        this.ctx.arc(
            cfg.center.x, cfg.center.y, r || 10,
            a1,
            a2,
            clockwise
        );
        this.ctx.stroke();
    },
    text: function (text, pos) {

        this.ctx.fillText(text, pos.x, pos.y);
    }
};

export default RenderTools;
