<template>
  <router-view/>
  <OrientationWarning />
</template>

<script>
import OrientationWarning from '@/components/OrientationWarning.vue'
export default {
  name: 'App',
  components: {
    OrientationWarning
  }
}
let vh;

function orientationChanged() {
  const timeout = 120;
  return new window.Promise(function(resolve) {
    const go = (i, height0) => {
      window.innerHeight != height0 || i >= timeout ?
        resolve() :
        window.requestAnimationFrame(() => go(i + 1, height0));
    };
    go(0, window.innerHeight);
  });
}


const setVHUnit = function () {
  vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}
setVHUnit()
var cbs = [];
window.addResizeCallback = function(fn) {
  cbs.push(fn)
};
window.addResizeCallback(setVHUnit);
var afterOrientationChanged = function() {
  orientationChanged().then(function() {
    cbs.forEach(function(fn) {
      fn()
    });
  });
};
window.addEventListener('resize', function() {
  afterOrientationChanged();
})

setTimeout(afterOrientationChanged, 500);
</script>

<style lang="scss">
@import "assets/styles/fonts.scss";
@import "assets/styles/variables.scss";
@import "assets/styles/global.scss";
@import "assets/styles/overrides.scss";

#app {
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-height: calc(var(--vh, 1vh) * 100);
  z-index: 1;
}
</style>
