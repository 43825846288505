const PrizeController = {
  init: function() {
      PrizeController.set(PrizeController.get());
  },
  set: function(val) {
    ARController.setCookie( 'prizeNotify', JSON.stringify({win: val.win, id: val.id, prize: val.prize}),7 );
    store.set('prizeNotify', val.win);
  },
  get: function(){
    try{
      var prize = ARController.getCookie( 'prizeNotify' );
      return JSON.parse( prize ) || { win: false };
    }catch( e ){
      return { win: false };
    }
  }
};
export default PrizeController;
