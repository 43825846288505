/**
 * Created by Ivan Kubota on 4/12/18.
 * Only the author can use it for commercial purpose right now.
 * You can use email to contact me: zibx@quokka.pub
 */

var Path = function(list) {
    this.children = [];
    if(Array.isArray(list)){
        for( let i = 0, _i = list.length; i < _i; i++ ){
            this.addChild(list[ i ])
        }
    }else{
        for( let i = 0, _i = arguments.length; i < _i; i++ ){
            this.addChild(arguments[ i ])
        }
    }
};
Path.prototype = {
    childrenLengths: [],
    addChild: function(child) {
        this.children.push(child)
    },

    move: function(x, y) {
        if(x instanceof Point){
            y = x.y;
            x = x.x;
        }
        return new Path(this.children.map(function(child) {
            return child.move(x,y);
        }))
    },
    scale: function(scale, origin) {
        /*if(typeof sx === 'number'){
            if(sy instanceof Point){
                origin = sy;
                sy = sx;
            }else if(typeof sy === 'number'){
                sy = sx;
            }
        }else if(sx instanceof Point){
            sy = sx.y;
            sx = sx.x;
        }*/
        return new Path(this.children.map(function(child) {
            return child.scale(scale, origin);
        }))
    },
    rotate: function(angle, origin) {
        return new Path(this.children.map(function(child) {
            return child.rotate(angle, origin);
        }))
    },

    length: function() {
        var length = 0;
        var childrenLengths = this.childrenLengths = [];
        this.children.forEach(function(child) {
            var childLength = child.length();

            length += childLength;
            childrenLengths.push(length);
        });
        return length;
    },
    getPathPoint: function(distance) {
        var previous = 0, inChild = null;
        for( let i = 0, _i = this.childrenLengths.length; i < _i; i++ ){
            if(distance<this.childrenLengths[ i ]){
                inChild = i;
                break;
            }
            previous = this.childrenLengths[ i ];
        }
        if(inChild === null){
            return false;
        }

        return this.children[inChild].getPathPoint((distance-previous)/(this.childrenLengths[ inChild ]-previous));
    },
    draw: function() {
        this.children.forEach(function(child) {
            child.draw();
        });
    },
    path: function(ctx) {
        this.children.forEach(function(child) {
            child.path(ctx);
        });
    },
    pathReverse: function(ctx) {
        for( var i = this.children.length-1; i >=0; i-- ){
            var child = this.children[ i ];
            child.pathReverse(ctx);
        }
    },
    intersect: function (path, debug, self) {
        self = self || false;
        var collidesList = [];
        this.children.forEach(function (myChild, i) {
            path.children.forEach(function (hisChild, j) {
                if(i === j && self)
                    return;
                var collides = myChild.intersect(hisChild);
               /* R.ctx.strokeStyle = '#ff1c15'
                myChild.draw()
                hisChild.draw()*/
                if(collides){
                    if(!Array.isArray(collides))
                        collides = [collides];
                    if(collides.length === 0){
                        collides = false;
                    }else{

                        for( let j = 0, _j = collides.length; j < _j; j++ ){
                            const collide = collides[ j ];
                            collidesList.push(collide);
                            if(debug){
                                R.circle( collide.point, '#5b2db2', 3 )
                            }
                        }
                    }

                }
                /*if(!collides){
                    R.ctx.strokeStyle = '#3072ff'
                    myChild.draw()
                    hisChild.draw()
                }*/

            })
        });
        if(collidesList.length === 0)
            return false;
        for( var i = 0, _i = collidesList.length; i < _i; i++ ){
            var collidesListElement = collidesList[ i ];
            collidesListElement.p1 = this;
            collidesListElement.p2 = path;
        }
        return collidesList;
    },
    getNearestPoint: function(point) {
        var children = this.children,
            min = Infinity, nearest = false,
            nearestPathLength, beforeMatchPathLength = 0;
        for( let i = 0, _i = children.length; i < _i; i++ ){
            const child = children[ i ],
                childPoint = child.getNearestPoint(point),
                distanceFromPoint = point.distancePow2(childPoint),
                childPathLength = child.length();
            if(distanceFromPoint<min){
                nearest = childPoint;
                min = distanceFromPoint;
                nearestPathLength = beforeMatchPathLength+nearest.percent*childPathLength;
            }
            beforeMatchPathLength += childPathLength;
        }
        nearest.distanceFromStart = nearestPathLength;
        nearest.percent = nearestPathLength/beforeMatchPathLength;
        return nearest;

    }
};

export default Path;
