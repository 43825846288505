/**
 * Created by Ivan Kubota on 4/12/18.
 * Only the author can use it for commercial purpose right now.
 * You can use email to contact me: zibx@quokka.pub
 */

var bound = function(a, from, to) {
    var d = (to-from);
    return (a < from ? a + Math.ceil( Math.abs(a/d))*d : a)% d;
};
var boundAngel = function(a) {
    return bound(a, 0, PI2);
};

var EPS = 0.001;

var RAD2DEG = 180/Math.PI;
var PI2 = Math.PI*2;
var PI_HALF = Math.PI/2;

export {EPS, boundAngel, bound, RAD2DEG, PI2, PI_HALF};



