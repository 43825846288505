'use strict';

var hexToDec = function( hex ){
  return parseInt( hex, 16 );
};
var stringDefinitions = {
  3: function( rgb ){
    return rgb.split( '' ).map( function( char ){
      return char + char;
    } ).map( hexToDec )
  },
  6: function( rrggbb ){
    return [
      rrggbb.substr( 0, 2 ),
      rrggbb.substr( 2, 2 ),
      rrggbb.substr( 4, 2 )
    ]
      .map( hexToDec );
  },

};

function Color( r, g, b, a ){
  var cfg = {};
  if( typeof r === 'object' ){
    if( Array.isArray( r ) ){
      cfg.r = r[ 0 ] || 0;
      cfg.g = r[ 1 ] || 0;
      cfg.b = r[ 2 ] || 0;
      cfg.a = r.length === 4 ? r[ 3 ] : 1
    }else{
      cfg = r;
      cfg.a = 'a' in r ? r.a : 1;
    }
  }else if( typeof r === 'string' ){
    var stringColor;
    if( r.charAt( 0 ) === '#' ){
      stringColor = r.substr( 1 );
    }else{
      stringColor = r;
    }
    var len = stringColor.length,
      fn = stringDefinitions[ len ];
    if( fn )
      return new Color( stringDefinitions[ len ]( stringColor ) );
  }else{
    cfg.r = r || 0;
    cfg.g = g || 0;
    cfg.b = b || 0;
    cfg.a = a !== void 0 ? a : 1
  }
  for( var k in cfg ){
    this[ k ] = cfg[ k ];
  }
}

Color.prototype = {
  toString: function(){
    var c = this.clone().clamp();
    return 'rgba(' + [ c.r | 0, c.g | 0, c.b | 0, c.a ] + ')';
  },
  clone: function(){
    return new Color( this );
  },
  clamp: function(){
    this.r = Math.max( 0, Math.min( 255, this.r ) );
    this.g = Math.max( 0, Math.min( 255, this.g ) );
    this.b = Math.max( 0, Math.min( 255, this.b ) );
    this.a = Math.max( 0, Math.min( 1, this.a ) );
    return this;
  },
  darken: function( ratio ){
    var hsl = rgbToHsl( this.r, this.g, this.b );
    hsl[ 2 ] = Math.min( 1, Math.max( 0, hsl[ 2 ] - hsl[ 2 ] * ratio ) );
    var c = new Color( hslToRgb.apply( null, hsl ) );
    Object.assign( this, c );
  }
};

function rgbToHsl( r, g, b ){
  r /= 255, g /= 255, b /= 255;
  var max = Math.max( r, g, b ), min = Math.min( r, g, b );
  var h, s, l = ( max + min ) / 2;

  if( max == min ){
    h = s = 0; // achromatic
  }else{
    var d = max - min;
    s = l > 0.5 ? d / ( 2 - max - min ) : d / ( max + min );
    switch( max ){
      case r:
        h = ( g - b ) / d + ( g < b ? 6 : 0 );
        break;
      case g:
        h = ( b - r ) / d + 2;
        break;
      case b:
        h = ( r - g ) / d + 4;
        break;
    }
    h /= 6;
  }

  return [ h, s, l ];
}

function hslToRgb( h, s, l ){
  var r, g, b;

  if( s == 0 ){
    r = g = b = l; // achromatic
  }else{
    var hue2rgb = function hue2rgb( p, q, t ){
      if( t < 0 ) t += 1;
      if( t > 1 ) t -= 1;
      if( t < 1 / 6 ) return p + ( q - p ) * 6 * t;
      if( t < 1 / 2 ) return q;
      if( t < 2 / 3 ) return p + ( q - p ) * ( 2 / 3 - t ) * 6;
      return p;
    }

    var q = l < 0.5 ? l * ( 1 + s ) : l + s - l * s;
    var p = 2 * l - q;
    r = hue2rgb( p, q, h + 1 / 3 );
    g = hue2rgb( p, q, h );
    b = hue2rgb( p, q, h - 1 / 3 );
  }

  return [ Math.round( r * 255 ), Math.round( g * 255 ), Math.round( b * 255 ) ];
}

Color.lerp = function( c1, c2, val ){
  c1 = new Color( c1 );
  c2 = new Color( c2 );
  return new Color(
    ( c2.r - c1.r ) * val + c1.r,
    ( c2.g - c1.g ) * val + c1.g,
    ( c2.b - c1.b ) * val + c1.b,
    ( c2.a - c1.a ) * val + c1.a
  );
};

export default Color;
