const until = function(cond, action) {
  if(!cond())
    if(action() === false){
      return;
    }
  setTimeout(function() {
    if(!cond())
      setTimeout(()=>until(cond, action), 100);
  }, 200);
};


const StateController = window.StateController = {
  until,
  init: function(c1,c2, ARController, c3) {
    store.sub(['currentPage'], function(page) {
      console.info('Current page:', page)
    });
    store.sub(['scenesLoaded'], function(val) {
      if(val === false){
        console.log('scenes not loaded')
        until(function() {
          return StateController.isSameHash(location.hash, '/#/');
        }, function() {
          console.log('try')

          //localStorage.removeItem('_aaftr_lot', false);ARController.initSecret()
          console.log(router.currentRoute.value.name)
          if(!router.currentRoute || !router.currentRoute.value || router.currentRoute.value.name !== 'Prize'){
            router.replace( '/' );
          }else{
            return false;
          }
        });
      }
    })
  },
  isSameHash: function(a,b) {
    var Bs = (b+'').split(',');
    for( var i = 0, _i = Bs.length; i < _i; i++ ){
      var b1 = Bs[ i ];
      if(a.replace(/^\/(door)?/,'').toLowerCase().trim() === b1.replace(/^\//,'').toLowerCase().trim())
        return true
    }
    return false;
  }
};
export default StateController;
