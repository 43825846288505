import Point from './Tapir2D/Objects/Primitives/Point.js';
import {boundAngel, EPS} from './Tapir2D/generic.js';
import CameraCTX from './Tapir2D/CameraCTX.js';

import Line from './Tapir2D/Objects/Primitives/Line.js';
import Path from './Tapir2D/Objects/Primitives/Path.js';
import Collision from './Tapir2D/Objects/Primitives/Collision.js';
import ArcPrimitive from './Tapir2D/Objects/Primitives/Arc.js';
import RoundPath from './Tapir2D/Objects/Primitives/RoundPath.js';
import Vector from './Tapir2D/Objects/Primitives/Vector.js';

import RenderTools from './Tapir2D/RenderTools.js';
import Color from './Tapir2D/Draw/Color.js';

import Figure from './Tapir2D/Objects/Figure.js';
import extend from './Tapir2D/Objects/extend.js';
import start from './Tapir2D/Objects/start.js';
import Basic from './Tapir2D/Objects/Basic.js';
import Border from './Tapir2D/Objects/Border.js';
import Camera from './Tapir2D/Objects/Camera.js';
import Key from './Tapir2D/Objects/Key.js';
import Arc from './Tapir2D/Objects/Arc.js';

var F = Figure;

var c1 = document.getElementById('c2');
var c2 = document.getElementById('c1');
var c3 = document.getElementById('c3');
var w, h;

var resize = function(initial) {
  var oldWidth = w||0, oldHeight = h||0;


  ctx.width = w = document.body.clientWidth;
  ctx.height = h = document.body.clientHeight;
  if(window.ARController){
    ARController.w = w;
    ARController.h = h;

  }else{
    setTimeout(resize, 100);
  }
  c1.setAttribute('width', w);
  c1.setAttribute('height', h);

  c2.setAttribute('width', w);
  c2.setAttribute('height', h);
  c3.setAttribute('width', w);
  c3.setAttribute('height', h);

};
window.addResizeCallback(resize)



var RAD2DEG = 180/Math.PI;
var ctx = new CameraCTX(c1.getContext('2d'));
//ctx.sharp = true;
var R = global.R = new RenderTools(ctx);
var PI2 = Math.PI*2,
  PI_HALF = Math.PI/2;

var MAX_WHEEL_ANGLE = 28/180*Math.PI;




var t = +new Date();
var w = c1.clientWidth,
  h = c1.clientHeight;


var pedestal = new Point(0, 0);

var T = 0;

var points = [
  new Point(9*3, 33*3),
  new Point(0,0),
  new Point(-157*2, 75*2.4),
  new Point(0,99)
];

Figure.define('path', function(cfg){
  var figure = F.start('begin', cfg);
  figure.add(
    RoundPath(
      F.Border( points[0], points[1]),
      F.Border( points[2], points[3]),
      50
    )
  );
  [figure.items[0].from, figure.items[0].to] = [figure.items[0].from, figure.items[0].to].reverse();
  [figure.items[1].from, figure.items[1].to] = [figure.items[1].from, figure.items[1].to].reverse();
  figure.items[2].angle -= figure.items[2].segment
  figure.items[2].clockwise = true;

  figure.items.splice(1, 0, figure.items.pop())
  return figure;
});

var cfg = global.cfg = {

  get: function(name) {
    return this[name];
  }
};
var p, path;
var generatePath = function() {
  p = new Figure.path();
  path = new Path(p.filter(a=>typeof a !== 'string'));
}
generatePath();

var nearest = new Arc({radius:0.5, fill:true});
var highlight = new Arc({radius:3, fill:true});

var cam = window.cam = new Figure.Camera({center: {x: 10, y: 10}, angle: 0});
var key = new Figure.Key({center: {x: -60, y: 200}, angle: 0});
var fps = 0;


var drawing = false;
var draw = function() {
  if(!drawing){
    drawing = true;
    _draw();
    drawing = false;
  }
};
var REALDRAW = true;
var _draw = function() {
  var dt = -(t - (t = +new Date())), sin = Math.sin;
  if(dt>50)
    dt = 50;
  if(dt< 2)
    return;

  T+=dt;


  if(REALDRAW){
    ctx.cleanFrameStart();

    ctx.ctx.fontSize = '20'
    ctx.ctx.fillText( ( 1000 / dt ).toFixed( 1 ), 10, 40 );


    var figure;
    ctx.rotate( ctx.rotation, function(){
      p.filter( function( item ){
        if( typeof item === 'string' ){
          figure = item;
          return false;
        }else{
          item.figure = figure;
          return true;
        }
      } ).forEach( item =>
        item.draw( ctx, dt ) );
    } );


    ctx.ctx.fillStyle = '#ffffff';
    ctx.beginPath();
    ctx.arc( pedestal.x, pedestal.y, 6 + sin( T / 200 ) / 1.5, 0, Math.PI * 2, false )
    ctx.fill();
    ctx.closePath();

    if( PointerPosition ){
      ctx.fillText(
        PointerPosition, PointerPosition.x, PointerPosition.y
      )
    }
  }
  var nearestPoint = path.getNearestPoint( cam.center );
  nearest.center.x = nearestPoint.x;
  nearest.center.y = nearestPoint.y;

  nearest.radius = 1.5;
  ctx.ctx.fillStyle = '#ff00ff';
  if(REALDRAW){
    nearest.draw();
  }

  path.length()

  /*var nextPoint = path.getPathPoint(path.length()*Math.min(1,(nearestPoint.percent+0.02)));
  nearest.center.x = nextPoint.x;
  nearest.center.y = nextPoint.y;
  nearest.radius = 1;
  ctx.ctx.strokeStyle = '#961e96';
  nearest.draw();*/

  var nextPoint = path.getPathPoint(path.length()*Math.min(1,(nearestPoint.percent+0.04)));
  nearest.center.x = nextPoint.x;
  nearest.center.y = nextPoint.y;
  nearest.radius = 1;
  ctx.ctx.fillStyle = '#540754';
  if(REALDRAW){
    nearest.draw();
  }




  //console.log(ARController.ctx.camera.position, ARController.ctx.camera.rotation, plane1.position,plane1.rotation)


  //if(PointerPosition){
    var nextAngle = Math.PI-boundAngel(cam.angle-cam.center.subClone( nextPoint ).getAngle()-Math.PI/2);
/*
    if(nextAngle<-Math.PI/3*2)
      nextAngle = -Math.PI/3*2;
    if(nextAngle>Math.PI/3*2)
      nextAngle = Math.PI/3*2;
*/

    key.angle = (key.angle*9+nextAngle)/12
    if(window.ARController)
      ARController.keyRotation = -key.angle
  //}
  //begalo.center = path.getPathPoint((sin(T/1000)+1)/2*path.length());
  //begalo.draw();

  var smthPressed = false;
  if(keyState.w || keyState.arrowup){
    cam.center.add(new Point(0, -dt/10).rotate(cam.angle));
    smthPressed = true;
  }
  if(keyState.s || keyState.arrowdown){
    cam.center.add(new Point(0, dt/10).rotate(cam.angle))
    smthPressed = true;
  }
  if(keyState.a || keyState.arrowleft){
    cam.angle -= dt/250;
    smthPressed = true;
  }
  if(keyState.d || keyState.arrowright){
    cam.angle += dt/250;
    smthPressed = true;
  }

  if(REALDRAW){
    key.draw( ctx );
    cam.draw( ctx );
  }

  if(nearestMovable){
    highlight.center = nearestMovable;
    if(REALDRAW){
      ctx.ctx.fillStyle = '#3a9153';
      highlight.draw();
    }
  }
  needRender = smthPressed;
needRender = true;
  if(needRender){
    requestAnimationFrame( draw );
  }
};
var needRender = true;
resize(true);
var keyState = {};

ctx.zoom = 3;
ctx.rotation = 0;
ctx.pos = new Point().sub(w/ctx.zoom/3*2,h/ctx.zoom/4);


draw();

var nearestMovable, moving;
var PointerPosition;
c1.addEventListener('mousemove', function(e) {

  var pointer = PointerPosition = ctx.pointFromGlobal(new Point(e.layerX, e.layerY));
  nearestMovable = points.filter(p=>p.distance(pointer) < 10)[0];
  if(e.buttons === 0){
    moving = false;
  }
  if(e.buttons === 1){
    if(nearestMovable)
      moving = nearestMovable;
  }

  if(moving){
    moving.x = pointer.x;
    moving.y = pointer.y;
    generatePath();
  }
  draw();
});
window.addEventListener('keydown', function(e) {
  if(!keyState[e.key.toLowerCase()]){
    keyState[ e.key.toLowerCase() ] = true;
    draw();
  }
});
window.addEventListener('keyup', function(e) {
  keyState[e.key.toLowerCase()] = false;
  draw();
});
