/**
 * Created by Ivan Kubota on 4/12/18.
 * Only the author can use it for commercial purpose right now.
 * You can use email to contact me: zibx@quokka.pub
 */

import Figure from './Figure.js'
import Point from './Primitives/Point.js';

var figureStorage = {};
Figure.get = function(figure, part) {
    return figureStorage[figure].hash[part];
};
Figure.start = function(name) {
    if(!(this instanceof Figure.start)){
        return new Figure.start(name);
    }
    this.items = [];
    this.hash = {};
    this.offsets = [];
    this._offset = new Point(0,0);
    if(name){
        figureStorage[ name ] = this;
    }
};

Figure.start.prototype = {
    add: function() {
        var label = void 0;
        var args = Figure.flat([].slice.call(arguments));
        for( var i = 0, _i = args.length; i < _i; i++ ){
            var argument = args[ i ];
            if(typeof argument === 'string'){
                label = argument;
            }else{
                if('offset' in argument){
                    argument.offset( this._offset );
                }
                this.items.push(argument);
                if(label){
                    this.hash[label] = argument;
                    label = void 0;
                }
            }
        }
        return this;
    },
    conify: function(density) {
        Figure.conify(this.items, density);
    },
    get: function(name) {
        return this.hash[name];
    },
    offset: function(point, fn) {
        this.offsets.push(this._offset);
        this._offset = point;
        fn();
        this._offset = this.offsets.pop();
    },
    rotate: function(pivot, angle) {
        this.items.forEach(function(item) {
            if(Array.isArray(item)){
                Figure.start.prototype.rotate.call({items: item}, pivot, angle);
            }else{
                item._points.forEach( function( pointName ){
                    item[ pointName ] = new Point( item[ pointName ] ).sub( pivot ).rotate( angle / 180 * Math.PI );
                } )
            }
        })
    }
};
