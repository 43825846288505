var tDelta = 0;
var tLast = 0;
var ctx, w, h, grow = true;
var duration = 5000;
var clearRect = {};
const LinesAnimator = window.LinesAnimator = {
  animating: false,
  animateLines: function(cb) {
    grow = true;
    clearRect = D('.prize-card')[0].getClientRects()[0];
    LinesAnimator.afterAnimateLines = cb;

    if(LinesAnimator.animating)
      return;
    LinesAnimator.animating = true;
    tLast = +new Date();

    requestAnimationFrame(LinesAnimator._animate);

    ctx = document.getElementById('c3').getContext('2d');
    w = ARController.w; h = ARController.h;




  },
  unAnimateLines: function() {
    grow = false;

    if(LinesAnimator.animating)
      return;
    LinesAnimator.animating = true;
    requestAnimationFrame(LinesAnimator._animate);

  },
  _animate: function() {

    var tNow = +new Date();
    var dt = Math.min(tNow-tLast, 200);
    tLast = tNow;
    if(!grow)
      dt=-dt;

    tDelta+=dt;

    ctx.clearRect(0,0,w,h)

    if(tDelta>duration){
      tDelta = 0;
      LinesAnimator.afterAnimateLines();
      LinesAnimator.animating = false;
      return;
    }else if(tDelta < 0){
      tDelta = 0;
      LinesAnimator.animating = false;
      return;
    }



    var delta = tDelta;
    if(delta>duration){
      return ARController.afterAnimateLines();
    }


    for(var l = 0; l < 10; l++){
      ctx.beginPath();
      ctx.lineWidth = 2+delta/duration*3;
      var toLeft = true;
      var point = [w+40,h/3+l*9], angle = 2.7-l*0.05;
      ctx.moveTo(point[0], point[1]);
      var to = Math.pow(delta,1.1)/duration*60;
      for (var i = 1; i < to; i++){

        angle+=0.01;

        point[0] = point[0]+Math.cos(angle)*10;
        point[1] = point[1]+Math.sin(angle)*10;
        ctx.lineTo(point[0], point[1]);//, xc, yc);
        if(point[0]<-80){
          point[0] = -30;
          point[1] -= h/5;
          toLeft = false;
          angle+=3.7+l*0.01+Math.PI;
          ctx.lineWidth = 2+delta/duration*10
          break;

        }
      }


      ctx.strokeStyle = '#fff'
      ctx.stroke()

      if(!toLeft){
        ctx.beginPath();
        ctx.lineWidth = 2+delta/duration*3;
        ctx.moveTo(point[0], point[1]);
        for( ; i < to; i++ ){
          angle-=0.002*l;
          var p0 = point.slice();
          point[0] = point[0]-Math.cos(angle)*10;
          point[1] = point[1]-Math.sin(angle)*10;
          ctx.lineTo(point[0], point[1]);


        }
        ctx.strokeStyle = '#fff'
        ctx.stroke()
      }


    }
    ctx.clearRect(clearRect.x|0,clearRect.y|0,clearRect.width|0,clearRect.height|0);
    requestAnimationFrame(LinesAnimator._animate);

  }
};
export default LinesAnimator;
