import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './js/canvas';
import './js/AR/main.js';
import ARController from './js/AR/Controller.js';


window.router = router;
ARController.init();
createApp(App).use(router).mount('#app')
