/**
 * Created by Ivan Kubota on 4/12/18.
 * Only the author can use it for commercial purpose right now.
 * You can use email to contact me: zibx@quokka.pub
 */

import Point from './Point.js';
import Collision from './Collision.js';
import {boundAngel, PI2, PI_HALF} from '../../generic.js';
import Vector from './Vector.js';

var Arc = function(cfg) {
    this.set(cfg);
};
Arc.prototype = {
    center: new Point(0,0),
    radius: 0,
    angle: 0,
    segment: Math.PI*2,
    clockwise: true,
    fill: false,
    intersect: function(other) {
        return other.intersectArc(this);
    },
    intersectArc: function(arc) {
        var distance = arc.center.distance(this.center);
        if(distance>this.radius+arc.radius) {
            return false;
        }
        if(distance<Math.abs(this.radius-arc.radius)) {
            return false;
        }
        var dx = arc.center.x - this.center.x,
            dy = arc.center.y - this.center.y;

        var d = distance;
        var r0 = this.radius, r1 = arc.radius;

        var a = ((r0*r0) - (r1*r1) + (d*d)) / (2.0 * d) ;

        var x2 = this.center.x + (dx * a/d),
        y2 = this.center.y + (dy * a/d);

        var h = Math.sqrt((r0*r0) - (a*a));

        var rx = -dy * (h/d);
        var ry = dx * (h/d);

        var xi = x2 + rx,
            xi_prime = x2 - rx,
            yi = y2 + ry,
            yi_prime = y2 - ry;
        var out = [];
        var p1 = new Point(xi,yi);
        var p2 = new Point(xi_prime,yi_prime);


        if(arc.containAngle(p1.subClone(arc.center).getAngle()) && this.containAngle(p1.subClone(this.center).getAngle())){
            out.push(p1)
        }
        if(arc.containAngle(p2.subClone(arc.center).getAngle()) && this.containAngle(p2.subClone(this.center).getAngle())){
            out.push(p2)
        }

        return out.map((point)=>new Collision(point, this, arc));
    },
    intersectLine:function(line){
        //p1 is the first line point
        //p2 is the second line point
        //c is the circle's center
        //r is the circle's radius

        var r = this.radius, c = this.center, moveXSlightly = false, originX;


        var p3 = line.from.subClone(this.center);//{x:p1.x - c.x, y:p1.y - c.y}; //shifted line points
        var p4 = line.to.subClone(this.center);//{x:p2.x - c.x, y:p2.y - c.y};
        var xEqual = false;
        if(p4.x===p3.x)
            xEqual = true;

        var tan = (p4.y - p3.y) / (p4.x - p3.x); //slope of the line

        var b = p3.y - tan * p3.x; //y-intercept of line

        var underRadical = Math.pow(r,2)*Math.pow(tan,2) + Math.pow(r,2) - Math.pow(b,2); //the value under the square root sign

        if (!xEqual && underRadical < 0) {
            //line completely missed
            return false;
        } else {
            if(xEqual){
                i1 = new Point(p3.x+c.x,Math.sqrt(r*r-p3.x*p3.x) +c.y);
                i2 = new Point(p3.x+c.x,-Math.sqrt(r*r-p3.x*p3.x) +c.y);


            }else{
                var t1 = ( -tan * b + Math.sqrt( underRadical ) ) / ( Math.pow( tan, 2 ) + 1 ); //one of the intercept x's
                var t2 = ( -tan * b - Math.sqrt( underRadical ) ) / ( Math.pow( tan, 2 ) + 1 ); //other intercept's x

                var i1 = new Point( t1 + c.x, tan * t1 + b + c.y ); //intercept point 1
                var i2 = new Point( t2 + c.x, tan * t2 + b + c.y ); //intercept point 2
            }
            var points = [i1, i2].filter(
                (point)=>
                    line.rectContainsPoint(point) &&
                    this.containAngle(point.subClone(this.center).getAngle())
            );
            if(points.length === 2 )
                if(points[1].distancePow2(points[0])<EPS)
                    points.length = 1;

            return points.map((point)=>new Collision(point, this, line));
        }
    },
    draw: function() {
        R.ctx.beginPath();
        R.ctx.arc(
            this.center.x, this.center.y, this.radius,
            boundAngel(this.angle),
            boundAngel(this.angle)+this.segment*(this.clockwise?1:-1),
            !this.clockwise
        );
        if(this.fill){
            R.ctx.fill();
        }else{
            R.ctx.stroke();
        }

        //R.ctx.fillText(this.debugText,this.center.x,this.center.y)
       /* R.ctx.fillText(
            'a:'+(this.getAngle*RAD2DEG).toFixed(0)+' s:'+
            (this.segment*RAD2DEG).toFixed(0),
            this.center.x, this.center.y)
        for(var i = 0; i < PI2; i+=0.1){
            var cococo = this.containAngle(i);
            R.circle(this.center.addClone(Math.cos(i)*this.radius, Math.sin(i)*this.radius),cococo?'#080':'#808',cococo?3:1)
            var p = this.center.addClone(Math.cos(i)*this.radius, Math.sin(i)*this.radius);
            R.ctx.fillText((i*RAD2DEG).toFixed(0),p.x, p.y)
        }*/
        //R.line({from:this.from, to:this.to}, this.color || '#00f', this.width || 2);
    },
    path: function(ctx) {
        var toAngle = this.getEndAngle(),
            fromAngle = boundAngel(this.angle)

        ctx.arc(
            this.center.x, this.center.y, this.radius,
            fromAngle,
            toAngle,
            !this.clockwise
        );
    },
    pathReverse: function(ctx) {
        var toAngle = this.getEndAngle(),
            fromAngle = boundAngel(this.angle)

        ctx.arc(
            this.center.x, this.center.y, this.radius,

            toAngle,
            fromAngle,
            this.clockwise
        );
    },
    containAngle: function(angle) {



        angle = boundAngel(angle);
        if(this.clockwise){
            return (angle >= this.angle && angle <= this.angle+this.segment) ||
                (angle >= this.angle -PI2 && angle <= this.angle+this.segment -PI2)||
                (angle >= this.angle +PI2 && angle <= this.angle+this.segment +PI2);
        }else{
            return (angle <= this.angle && angle >= this.angle-this.segment) ||
                (angle <= this.angle -PI2 && angle >= this.angle-this.segment -PI2)||
                (angle <= this.angle +PI2 && angle >= this.angle-this.segment +PI2);
        }
        return false
        var myAngle = this.angle;

        var bound = [myAngle+PI2, this.getEndAngle()+PI2];
        if(bound[0]> bound[1]){
            bound = [ bound[ 1 ], bound[ 0 ] ];
        }
        /*if(bound[0]<0){
            bound = [PI2+bound[0],PI2+bound[1]]
        }*/

        //if(bound[1]>PI2){
            //bound = [bound[0]+PI2,bound[1]+PI2]
        //}

        return angle>=bound[0] && angle <= bound[1];
    },
    length: function() {
        return this.segment*this.radius;
    },
    set: function(cfg) {
        for(var k in cfg){
            if(k in this.setters){
                this.setters[k].call(this, cfg[k]);
            }else{
                this[k] = cfg[k];
            }
        }
        return this;
    },
    setters: {
        angle: function( val) {
            this.angle = boundAngel(val)
        }
    },
    clone: function() {
        var clone = new Arc(this);
        clone.center = clone.center.clone();
        return clone;
    },
    getStartPoint: function () {
        return this.center.addClone(
            Math.cos(this.angle)*this.radius,
            Math.sin(this.angle)*this.radius,
        )
    },
    getEndAngle: function(){
        return this.angle+this.segment*(this.clockwise?1:-1);
    },
    getEndAnglePercent: function(percent){
        return this.angle+this.segment*percent*(this.clockwise?1:-1);
    },
    getEndPoint: function () {
        return this.center.addClone(
            Math.cos(this.getEndAngle())*this.radius,
            Math.sin(this.getEndAngle())*this.radius,
        )
    },
    getPathPoint: function(percent) {
        return this.center.addClone(
            Math.cos(this.getEndAnglePercent(percent))*this.radius,
            Math.sin(this.getEndAnglePercent(percent))*this.radius,
        )
    },
    getNearestPoint: function(point) {
        var angle = point.subClone(this.center).getAngle(),
            result;
        if(this.containAngle(angle)){
             result = new Vector(new Point( this.radius, 0 ).rotate( angle ).addClone( this.center ));
             result.angle = angle+(this.clockwise?PI_HALF:-PI_HALF);
             result.percent = boundAngel((angle - this.angle)*(this.clockwise?1:-1))/this.segment;
        }else{
            var startPoint = this.getStartPoint(),
                endPoint = this.getEndPoint();

            if(startPoint.distancePow2(point) < endPoint.distancePow2(point)){
                result = new Vector(startPoint);
                result.angle = this.angle+(this.clockwise?PI_HALF:-PI_HALF);
                result.percent = 0;
            }else{
                result = new Vector(endPoint);
                result.angle = this.getEndAngle()+(this.clockwise?PI_HALF:-PI_HALF);
                result.percent = 1;

            }
        }

        return result;
    }
};

export default Arc;
