/**
 * Created by Ivan Kubota on 4/12/18.
 * Only the author can use it for commercial purpose right now.
 * You can use email to contact me: zibx@quokka.pub
 */

import Point from './Point.js';
import Line from './Line.js';

var Vector = function(x, y) {
    if(x instanceof Point || x.hasOwnProperty('x')) {
        y = x.y;
        x = x.x;
        this.angle = x.angle || 0;
        this.length = x.length || 0;
    }
    this.x = x;
    this.y = y;

};
Vector.prototype = new Point(0,0);

Object.assign(Vector.prototype, {
    angle: 0,
    length: 0,
    toLine: function() {
        return new Line(this, this.addClone(new Point(this.length,0).rotate(this.angle)));
    }
});

export default Vector;
