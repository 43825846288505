<template>
  <div class="orientation-warning hidden">
    <div class="orientation-warning__text">
      <p>Bitte drehen Sie Ihr Telefon in den Hochformatmodus.</p>
    </div>
    <div class="orientation-warning__icon">
      <RotateIcon />
    </div>
  </div>
</template>

<script>
import RotateIcon from '@/assets/images/rotate.svg?inline'

export default {
  name: 'OrientationWarning',
  components: {
    RotateIcon
  }
}
</script>

<style lang="scss">
@import "./../assets/styles/variables.scss";
.orientation-warning {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  text-align: center;
  text-transform: uppercase;
  font-family: $accent-font;
  color: $foreground-color;
  background-color: $background-color;
  z-index: 1000000;

  @media (orientation: landscape) {
    display: flex !important;
  }

  @media (orientation: portrait) {
    display: none !important;
  }
}

.orientation-warning__icon {
  svg {
    display: block;
    width: 60px;
    height: 60px;
  }
}
</style>
