<template>
<!--  <div id="nav">-->
<!--    <router-link to="/">Home</router-link> |-->
<!--    <router-link to="/about">About</router-link>-->
<!--  </div>-->
  <StartScreen />
</template>

<script>
import StartScreen from '@/components/StartScreen.vue'

export default {
  name: 'Home',
  components: {
    StartScreen
  },
  mounted(){
    store.set('currentPage', 'Home');
  }
}
</script>
